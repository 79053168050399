import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addServicesToState } from "../../../redux";

import SelectorService from "../../../components/SelectorService";
import ListToDelete from "../../../components/ListToDelete";

import "./style.css"

function DeleteService() {
    const dispatch = useDispatch();

    const categories = useSelector(state => state.data.categories);
    const services = useSelector(state => state.data.services);
    const user = useSelector(state => state.user);

    // eslint-disable-next-line no-unused-vars
    const [serviceSelected, setServiceSelected] = useState(null);
    const [listToDelete, setListToDelete] = useState([]);
    const [toDelete, setToDelete] = useState('');

    useEffect(() => {
        const deleteService = async (servId) => {
            const req = await fetch(`https://api.pro-vide.eu/admin/services/${servId}`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${user.token}` }
            });
            const res = await req.json();
            
            if (res === true) {
                dispatch(addServicesToState(services.filter(d => d._id !== servId)));
                setListToDelete(services.filter(d => d._id !== servId));
                alert('Le service a bien été supprimé');
                setToDelete('');
                return
            }

            alert('Impossible de supprimer le service');
        }

        if (toDelete && toDelete !== '') {
            deleteService(toDelete);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDelete])

    return (
        <div className='form_container form_deleteService'>
            <i className="slash"></i>

            {categories && categories.length !== 0 &&
                <SelectorService allCategories={categories} allServices={services} setServiceSelected={setServiceSelected} setListToDelete={setListToDelete} />
            }

            <i className="slash"></i>

            <h2>Supprimer un service</h2>

            {listToDelete && listToDelete.length !== 0 &&
                <ListToDelete data={listToDelete} setToDelete={setToDelete} />
            }
        </div>
    )
}

export default DeleteService;
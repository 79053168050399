import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addCategoriesToState } from "../../../redux";

import SelectorParent from "../../../components/SelectorParent";
import NameAndText from "../../../components/NameAndText";
import InputImage from "../../../components/InputImage";

import "./style.css"

function CreateCategorie() {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const categories = useSelector(state => state.data.categories);

    const [formData, setFormData] = useState({
        parentId: '',

        name_fr: '',
        name_en: '',
        name_sp: '',
        name_de: '',

        text_fr: '',
        text_en: '',
        text_sp: '',
        text_de: '',

        image: ''
    });
    const [type, setType] = useState('prod');


    // Fonction pour créer une nouvelle catégorie
    const addNewCategory = async () => {
        if (formData.name_fr === '') {
            return
        }

        let bodyReq = {
            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,

            online: true,
            type: type
        }

        if (formData.parentId && formData.parentId !== null && formData.parentId !== '') {
            bodyReq.parentId = formData.parentId
        }

        if (formData.image && formData.image !== '' && formData.image !== null) {
            const bodyImg = new FormData();

            bodyImg.append('image', formData.image);

            const req = await fetch(`https://api.pro-vide.eu/admin/img`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${user.token}` // Correction de l'en-tête Authorization
                },
                body: bodyImg
            });
            const res = await req.json();

            if (res) {
                bodyReq.image = res;

                const reqCat = await fetch(`https://api.pro-vide.eu/admin/categories`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}` // Correction de l'en-tête Authorization
                    },
                    body: JSON.stringify(bodyReq)
                });
                const resCat = await reqCat.json();

                if (resCat && resCat._id) {
                    alert('La catégorie a bien été ajouté');
                    dispatch(addCategoriesToState([...categories, resCat]));
                    setFormData({
                        parentId: formData.parentId,

                        name_fr: '',
                        name_en: '',
                        name_sp: '',
                        name_de: '',

                        text_fr: '',
                        text_en: '',
                        text_sp: '',
                        text_de: '',

                        image: ''
                    })
                    return
                }
                alert('Impossible d\'ajouter la catégorie');
                return
            }
            alert('Impossible d\'ajouter l\'image de la catégorie');
            return
        }

        const req = await fetch(`https://api.pro-vide.eu/admin/categories`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(bodyReq)
        });
        const res = await req.json();

        if (res && res._id) {
            alert('La catégorie a bien été ajouter');
            dispatch(addCategoriesToState([...categories, res]));
            setFormData({
                parentId: formData.parentId,

                name_fr: '',
                name_en: '',
                name_sp: '',
                name_de: '',

                text_fr: '',
                text_en: '',
                text_sp: '',
                text_de: '',

                image: ''
            })
            return
        }
        alert('Impossible d\'ajouter la catégorie');
    }

    return (
        <div className='form_container form_createCategorie'>
            <i className="slash"></i>

            {categories && categories.length !== 0 &&
                <SelectorParent data={categories} setParentId={setFormData} type={type} setType={setType} />
            }

            <i className="slash"></i>

            <h2>Ajouter une catégorie</h2>

            <section className="sect_nameAndText_multiLang">
                <NameAndText lang='fr' holder='de la catégorie' nameValue={formData.name_fr} textValue={formData.text_fr} setFormData={setFormData} />
                <NameAndText lang='en' holder='de la catégorie' nameValue={formData.name_en} textValue={formData.text_en} setFormData={setFormData} />
                <NameAndText lang='sp' holder='de la catégorie' nameValue={formData.name_sp} textValue={formData.text_sp} setFormData={setFormData} />
                <NameAndText lang='de' holder='de la catégorie' nameValue={formData.name_de} textValue={formData.text_de} setFormData={setFormData} />
            </section>

            <InputImage formData={formData} setFormData={setFormData} />

            <button onClick={() => addNewCategory()} className="submitForm blue">Ajouter la catégorie</button>
        </div>
    )
}

export default CreateCategorie;
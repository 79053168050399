import icon_delete from './../../assets/svg/btn_close.svg';

import "./style.css"

function ShowProdAssociated({ data, setData, list, setList }) {   
    const updateList = (id) => {
        const newArray = data.filter(d => d._id !== id);
        setData(newArray);
        
        const newList = list.filter(d => d !== id);
        setList(newList);
    }

    return (
        <ul className='sect_showProdAssociated'>
            {data && data.length !== 0 && data.map((prod, idx) => (
                <li key={idx}>
                    <img onClick={() => updateList(prod._id)} className='btn_delete' src={icon_delete} alt="_" />

                    <div className="container-img">
                        <img src={prod.images} alt="Illustration" />
                    </div>

                    <p>{prod.name_fr}</p>
                </li>
            ))}
        </ul>
    )
}

export default ShowProdAssociated;
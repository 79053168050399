import "./style.css"

function Error() {

    return (
        <div className='page_error'>
            
        </div>
    )
}

export default Error;
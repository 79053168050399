import { useState } from "react";

import CreateService from './../../forms/services/CreateService';
import UpdateService from './../../forms/services/UpdateService';
import DeleteService from './../../forms/services/DeleteService';

import SelectorTypeForm from "../../components/SelectorTypeForm";

import "./style.css"

function Services() {
    const [typeForm, setTypeForm] = useState('add');

    return (
        <div className='page page_services'>
            <SelectorTypeForm type={typeForm} setType={setTypeForm} />

            {typeForm && typeForm === 'add' && <CreateService />}
            {typeForm && typeForm === 'edit' && <UpdateService />}
            {typeForm && typeForm === 'delete' && <DeleteService />}
        </div>
    )
}

export default Services;
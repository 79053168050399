import { useSelector } from 'react-redux';
import background_img from './../../assets/img/background.webp';
import logo_white from './../../assets/img/logo-pro-vide-white.png';

import "./style.css"

const subTitle = {
    fr: `Créateur de solution`,
    en: `Solution Creator`,
    sp: `Creador de soluciones`,
    de: `Lösungsersteller`
};
const text = {
    fr: `Nous imaginons, concevons et réalisons des accéssoires et équipement sous vide qui répondent à vos besoins`,
    en: `We imagine, design and produce vacuum accessories and equipment that meet your needs`,
    sp: `Imaginamos, diseñamos y producimos accesorios y equipos de vacío que satisfagan sus necesidades.`,
    de: `Wir konzipieren, entwerfen und produzieren Vakuumzubehör und -geräte, die Ihren Anforderungen entsprechen`
};

function Banner() {
    const lang = useSelector(state => state.lang);

    return lang && (
        <div className='banner'>
            <i className='hover'></i>
            <img className='background' src={background_img} alt="Background" />

            <img className='logo' src={logo_white} alt="Logo" />
            <p className='title'>PRO-VIDE</p>
            <p className='subTitle'>{subTitle[lang]}</p>
            <p className='text'>{text[lang]}</p>
        </div>
    )
}

export default Banner;
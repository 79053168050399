import { configureStore, createSlice } from "@reduxjs/toolkit";

// ------------------------------------------------------------------------------------------------
// State utilisateur

const userSlice = createSlice({
    name: "user",
    initialState: {
        admin_id: '',
        admin_pw: '',
        token: ''
    },
    reducers: {
        login: (state, action) => {
            const { admin_id, admin_pw, token } = action.payload;
            state.admin_id = admin_id || '';
            state.admin_pw = admin_pw || '';
            state.token = token || '';
        },
        logout: (state, action) => {
            state.admin_id = '';
            state.admin_pw = '';
            state.token = '';
        }
    }
})

export const { login, logout } = userSlice.actions;

// ------------------------------------------------------------------------------------------------
// State des données

const dataSlice = createSlice({
    name: "data",
    initialState: {
        categories: [],
        products: [],
        services: [],
        realization: [],
    },
    reducers: {
        addCategoriesToState: (state, action) => {
            state.categories = action.payload;
        },
        addProductsToState: (state, action) => {
            state.products = action.payload;
        },
        addServicesToState: (state, action) => {
            state.services = action.payload;
        },
        addRealizationToState: (state, action) => {
            state.realization = action.payload;
        }
    }
})

export const { addCategoriesToState, addProductsToState, addServicesToState, addRealizationToState } = dataSlice.actions;

// ------------------------------------------------------------------------------------------------
// State langue

const langSlice = createSlice({
    name: "lang",
    initialState: 'fr',
    reducers: {
        setLang: (state, action) => {            
            return action.payload;
        }
    }
})

export const { setLang } = langSlice.actions;

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

export const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        data: dataSlice.reducer,
        lang: langSlice.reducer,
    }
});
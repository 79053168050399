import { useEffect, useState } from "react";
import "./style.css";

function SelectorProductsAssociated({ allCategories, allProducts, list, setList }) {
    const [selectProdValue, setSelectProdValue] = useState('null');
    const [selectCatValue, setSelectCatValue] = useState('null');

    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);

    const [prodSelected, setProdSelected] = useState({ _id: '', name: '' });
    const [path, setPath] = useState([]);

    // Initialisation
    useEffect(() => {
        reloadSeletor();
    }, []);

    // Reload
    function reloadSeletor() {
        setCategories(allCategories.filter(d => !d.parentId && d.type === 'prod'));
        setProducts([]);

        setSelectProdValue('null');
        setSelectCatValue('null');

        setProdSelected({ _id: '', name: '' });
        setPath([]);
    }

    // Retour en arrière
    const setHistorical = (parent) => {
        setCategories(allCategories.filter(d => d.parentId === parent._id));
        setProducts(allProducts.filter(d => d.parentId === parent._id));

        setProdSelected({ _id: '', name: '' });
        setSelectProdValue('null');

        let newPath = [];
        let pathIsUpdate = false;

        for (let element of path) {
            if (pathIsUpdate) { continue }

            if (element._id === parent._id) {
                pathIsUpdate = true;
            }

            newPath.push(element);
        }

        setPath(newPath);
    }
    
    // Selection d'une catégorie
    const selectCategory = (value) => {
        if (value === 'null') {
            return;
        }

        const catSelected = allCategories.find(d => d._id === value);

        setPath([
            ...path,
            {
                _id: value,
                name: catSelected[`name_fr`]
            }
        ]);

        setCategories(allCategories.filter(d => d.parentId === value));
        setProducts(allProducts.filter(d => d.parentId === value));

        setSelectProdValue('null');
        setSelectCatValue('null');
    }

    // Selection d'un produit
    const selectProduct = (value) => {
        if (value === 'null') {
            return;
        }

        setProducts([]);
        const prod = allProducts.find(d => d._id === value);
        setProdSelected({ _id: prod._id, name: prod[`name_fr`] });
    }

    // Ajout à la list des produits associés
    const addToListAssociated = () => {
        if (!prodSelected._id || prodSelected._id === '') {
            return;
        }
        const prodAlreadyAssociated = list.find(d => d === prodSelected._id);

        if (prodAlreadyAssociated) {
            return
        }
        
        setList([...list, prodSelected._id]);
    }

    return (
        <div className='selector'>
            <div className="historical">
                <span>Produit :</span>
                <p className="parent_selected">{prodSelected && prodSelected.name ? prodSelected.name : 'Aucun'}</p>
                <button onClick={() => addToListAssociated()} className="addToListAssociated">Ajouter</button>
            </div>

            <div className="historical">
                <span>Localisation :</span>

                <button onClick={() => reloadSeletor()}>Catégories <i>{'>'}</i></button>

                {path && path.length !== 0 && path.map((e, idx) =>
                    <button
                        key={idx}
                        onClick={() => setHistorical(e)}
                    >{e.name} <i>{'>'}</i></button>
                )}
            </div>

            {categories && categories.length !== 0 &&
                <select
                    value={selectCatValue}
                    onChange={(e) => selectCategory(e.target.value)}
                >
                    <option value='null'>Choisissez une catégorie</option>
                    {categories.map((cat, idx) =>
                        <option
                            key={idx}
                            value={cat._id}
                        >{cat[`name_fr`]}</option>
                    )}
                </select>
            }

            {products && products.length !== 0 &&
                <select
                    value={selectProdValue}
                    onChange={(e) => selectProduct(e.target.value)}
                >
                    <option value='null'>Selectionnez un produit</option>
                    {products.map((prod, idx) =>
                        <option
                            key={idx}
                            value={prod._id}
                        >{prod[`name_fr`]}</option>
                    )}
                </select>
            }
        </div>
    );
}

export default SelectorProductsAssociated;

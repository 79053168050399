import { useEffect, useState } from "react";

import flag_fr from './../../assets/svg/flag-fr.svg'
import flag_en from './../../assets/svg/flag-en.svg'
import flag_sp from './../../assets/svg/flag-es.svg'
import flag_de from './../../assets/svg/flag-de.svg'

import "./style.css";

function ListDataProducts({ lastData, formData, setFormData }) {
    const flags = [flag_fr, flag_en, flag_sp, flag_de];
    const langs = ['fr', 'en', 'sp', 'de'];

    const [stateList, setStateList] = useState({
        benefits_fr: Array(6).fill(''),
        benefits_en: Array(6).fill(''),
        benefits_sp: Array(6).fill(''),
        benefits_de: Array(6).fill(''),

        features_fr: Array(6).fill(''),
        features_en: Array(6).fill(''),
        features_sp: Array(6).fill(''),
        features_de: Array(6).fill(''),
    });

    // lastData loading
    useEffect(() => {
        if (lastData) {
            setStateList({
                benefits_fr: lastData.benefits_fr.length !== 0 ? lastData.benefits_fr : Array(5).fill(''),
                benefits_en: lastData.benefits_en.length !== 0 ? lastData.benefits_en : Array(5).fill(''),
                benefits_sp: lastData.benefits_sp.length !== 0 ? lastData.benefits_sp : Array(5).fill(''),
                benefits_de: lastData.benefits_de.length !== 0 ? lastData.benefits_de : Array(5).fill(''),
    
                features_fr: lastData.features_fr.length !== 0 ? lastData.features_fr : Array(5).fill(''),
                features_en: lastData.features_en.length !== 0 ? lastData.features_en : Array(5).fill(''),
                features_sp: lastData.features_sp.length !== 0 ? lastData.features_sp : Array(5).fill(''),
                features_de: lastData.features_de.length !== 0 ? lastData.features_de : Array(5).fill(''),
            })
        }
    }, [lastData])

    // formData return
    useEffect(() => {
        if (stateList) {
            setFormData({ ...formData, ...stateList });
        }
    }, [stateList])

    // Change value
    const handleChange = (e, n, field) => {
        const value = e.target.value;

        let newList = [...stateList[field]];
        newList[n] = value;

        setStateList({ ...stateList, [field]: newList });
    }

    // Delete input
    const delInputToList = (list, field) => {
        if (list.length <= 1) {
            return
        }

        let newList = [...list];
        newList.pop();

        setStateList({ ...stateList, [field]: newList });
    }

    // Add input
    const addInputToList = (list, field) => {
        let newList = [...list, ''];
        setStateList({ ...stateList, [field]: newList });
    }

    return stateList && (
        <div className='listDataProducts'>
            <h3>Caractéristiques</h3>
            <div className="list_features">
                {langs.map((lang, idx) =>
                    <ul key={idx}>
                        <li>
                            <label>Caractéristiques ({lang}) <img src={flags[idx]} alt='_' /></label>
                        </li>

                        {stateList[`features_${lang}`].map((feat, id) =>
                            <li key={id}>
                                <input
                                    type="text"
                                    value={feat}
                                    placeholder='Sasissez votre caractéristique'
                                    onChange={(e) => handleChange(e, id, `features_${lang}`)}
                                />
                            </li>
                        )}

                        <div className="editor">
                            <button onClick={() => delInputToList(stateList[`features_${lang}`], `features_${lang}`)} className='dlt_input'>Retirer</button>
                            <button onClick={() => addInputToList(stateList[`features_${lang}`], `features_${lang}`)} className='add_input'>Ajouter</button>
                        </div>
                    </ul>
                )}
            </div>

            <h3>Avantages</h3>
            <div className="list_benefits">
                {langs.map((lang, idx) =>
                    <ul key={idx}>
                        <li>
                            <label>Avantages ({lang}) <img src={flags[idx]} alt='_' /></label>
                        </li>

                        {stateList[`benefits_${lang}`].map((bene, id) =>
                            <li key={id}>
                                <input
                                    type="text"
                                    value={bene}
                                    placeholder="Sasissez votre avantage"
                                    onChange={(e) => handleChange(e, id, `benefits_${lang}`)}
                                />
                            </li>
                        )}

                        <div className="editor">
                            <button onClick={() => delInputToList(stateList[`benefits_${lang}`], `benefits_${lang}`)} className='dlt_input'>Retirer</button>
                            <button onClick={() => addInputToList(stateList[`benefits_${lang}`], `benefits_${lang}`)} className='add_input'>Ajouter</button>
                        </div>
                    </ul>
                )}
            </div>
        </div>
    );
}

export default ListDataProducts;
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setLang } from '../../redux';

import VerifProduct from "../../components/VerifProduct";
import VerifService from "../../components/VerifService";
import VerifRealization from "../../components/VerifRealization";

import flag_fr from './../../assets/svg/flag-fr.svg'
import flag_en from './../../assets/svg/flag-en.svg'
import flag_sp from './../../assets/svg/flag-es.svg'
import flag_de from './../../assets/svg/flag-de.svg'

import "./style.css"

function Verification() {
    const dispatch = useDispatch();

    const lang = useSelector(state => state.lang);
    const db_provide = useSelector(state => state.data);

    const [counter, setCounter] = useState({
        products: 0,
        services: 0,
        realizations: 0,
    });
    const [dataToVerif, setDataToVerif] = useState({
        products: [],
        services: [],
        realizations: []
    })

    useEffect(() => {
        if (db_provide) {
            setDataToVerif({
                products: db_provide.products.filter(d => d.online === false),
                services: db_provide.services.filter(d => d.online === false),
                realizations: db_provide.realization.filter(d => d.online === false)
            });
        }
    }, [db_provide]);

    return dataToVerif && counter && (
        <div className='page_verification'>
            <div className="select_lang">
                <button onClick={() => dispatch(setLang('fr'))} className={lang === 'fr' ? 'actif' : ''}><img src={flag_fr} alt="_" /> FR</button>
                <button onClick={() => dispatch(setLang('en'))} className={lang === 'en' ? 'actif' : ''}><img src={flag_en} alt="_" /> EN</button>
                <button onClick={() => dispatch(setLang('sp'))} className={lang === 'sp' ? 'actif' : ''}><img src={flag_sp} alt="_" /> SP</button>
                <button onClick={() => dispatch(setLang('de'))} className={lang === 'de' ? 'actif' : ''}><img src={flag_de} alt="_" /> DE</button>
            </div>

            {dataToVerif.products.length !== 0 && counter.products !== dataToVerif.products.length && 
                <VerifProduct data={dataToVerif.products[counter.products]} setCounter={setCounter} />
            }

            {dataToVerif.services.length !== 0 && counter.products === dataToVerif.products.length && counter.services !== dataToVerif.services.length &&
                <VerifService data={dataToVerif.services[counter.services]} setCounter={setCounter} />
            }

            {dataToVerif.realizations.length !== 0 && counter.services === dataToVerif.services.length && counter.realizations !== dataToVerif.realizations.length && 
                <VerifRealization data={dataToVerif.realizations[counter.realizations]} setCounter={setCounter} />
            }
        </div>
    )
}

export default Verification;
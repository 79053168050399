import { useDispatch, useSelector } from 'react-redux';
import { addCategoriesToState, addProductsToState, addRealizationToState, addServicesToState } from './../../redux'

import chevron from './../../assets/svg/chevron_left.svg';

import "./style.css";

function ListOrder({ title, list, route }) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const data = useSelector(state => state.data);

    async function changeOrder(e1, e2) {
        const req = await fetch(`https://api.pro-vide.eu/admin/order${route}/${e1}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${user.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ changeFor: e2 })
        });
        const res = await req.json();
        return res
    }

    const switchTop = async (e, idx) => {
        const e1 = e._id;
        const e2 = list[idx === 0 ? list.length - 1 : idx - 1]._id

        const response = await changeOrder(e1, e2);

        if (response !== true) {
            alert('Erreur lors du changement d\'ordre')
            return
        }

        if (route === 'Categories') {
            let dataUsed = data.categories.filter(d => d._id !== e1 && d._id !== e2);
            const tempOrder = list[idx === 0 ? list.length - 1 : idx - 1].order;


            const element2 = { ...list[idx === 0 ? list.length - 1 : idx - 1], order: e.order };
            const element1 = { ...e, order: tempOrder };

            dataUsed.push(element1, element2);
            dataUsed = dataUsed.sort((a, b) => a.order - b.order);

            dispatch(addCategoriesToState(dataUsed))
            return
        }

        if (route === 'Products') {
            let dataUsed = data.products.filter(d => d._id !== e1 && d._id !== e2);
            const tempOrder = list[idx === 0 ? list.length - 1 : idx - 1].order;


            const element2 = { ...list[idx === 0 ? list.length - 1 : idx - 1], order: e.order };
            const element1 = { ...e, order: tempOrder };

            dataUsed.push(element1, element2);
            dataUsed = dataUsed.sort((a, b) => a.order - b.order);

            dispatch(addProductsToState(dataUsed))
            return
        }

        if (route === 'Services') {
            let dataUsed = data.services.filter(d => d._id !== e1 && d._id !== e2);
            const tempOrder = list[idx === 0 ? list.length - 1 : idx - 1].order;


            const element2 = { ...list[idx === 0 ? list.length - 1 : idx - 1], order: e.order };
            const element1 = { ...e, order: tempOrder };

            dataUsed.push(element1, element2);
            dataUsed = dataUsed.sort((a, b) => a.order - b.order);

            dispatch(addServicesToState(dataUsed))
            return
        }

        let dataUsed = data.realization.filter(d => d._id !== e1 && d._id !== e2);
        const tempOrder = list[idx === 0 ? list.length - 1 : idx - 1].order;


        const element2 = { ...list[idx === 0 ? list.length - 1 : idx - 1], order: e.order };
        const element1 = { ...e, order: tempOrder };

        dataUsed.push(element1, element2);
        dataUsed = dataUsed.sort((a, b) => a.order - b.order);

        dispatch(addRealizationToState(dataUsed))
    }

    const switchBottom = async (e, idx) => {
        const e1 = e._id;
        const e2 = list[idx === list.length - 1 ? 0 : idx + 1]._id

        const response = await changeOrder(e1, e2);

        if (response !== true) {
            alert('Erreur lors du changement d\'ordre')
            return
        }

        if (route === 'Categories') {
            let dataUsed = data.categories.filter(d => d._id !== e1 && d._id !== e2);
            const tempOrder = list[idx === list.length - 1 ? 0 : idx + 1].order;

            const element2 = { ...list[idx === list.length - 1 ? 0 : idx + 1], order: e.order };
            const element1 = { ...e, order: tempOrder };

            dataUsed.push(element1, element2);
            dataUsed = dataUsed.sort((a, b) => a.order - b.order);

            dispatch(addCategoriesToState(dataUsed))
            return
        }

        if (route === 'Products') {
            let dataUsed = data.products.filter(d => d._id !== e1 && d._id !== e2);
            const tempOrder = list[idx === list.length - 1 ? 0 : idx + 1].order;

            const element2 = { ...list[idx === list.length - 1 ? 0 : idx + 1], order: e.order };
            const element1 = { ...e, order: tempOrder };

            dataUsed.push(element1, element2);
            dataUsed = dataUsed.sort((a, b) => a.order - b.order);

            dispatch(addProductsToState(dataUsed))
            return
        }

        if (route === 'Services') {
            let dataUsed = data.services.filter(d => d._id !== e1 && d._id !== e2);
            const tempOrder = list[idx === list.length - 1 ? 0 : idx + 1].order;

            const element2 = { ...list[idx === list.length - 1 ? 0 : idx + 1], order: e.order };
            const element1 = { ...e, order: tempOrder };

            dataUsed.push(element1, element2);
            dataUsed = dataUsed.sort((a, b) => a.order - b.order);

            dispatch(addServicesToState(dataUsed))
            return
        }

        let dataUsed = data.realization.filter(d => d._id !== e1 && d._id !== e2);
        const tempOrder = list[idx === list.length - 1 ? 0 : idx + 1].order;

        const element2 = { ...list[idx === list.length - 1 ? 0 : idx + 1], order: e.order };
        const element1 = { ...e, order: tempOrder };

        dataUsed.push(element1, element2);
        dataUsed = dataUsed.sort((a, b) => a.order - b.order);

        dispatch(addRealizationToState(dataUsed));
    }

    return (
        <div className='listOrder'>
            <h2>{title}</h2>

            <ul>
                {list.map((e, idx) =>
                    <li key={idx}>
                        <span>
                            <img onClick={() => switchTop(e, idx)} className='chevron chevron_top' src={chevron} alt="_" />
                            <img onClick={() => switchBottom(e, idx)} className='chevron chevron_bottom' src={chevron} alt="_" />
                        </span>
                        <p>{e.name_fr}</p>
                    </li>
                )}
            </ul>
        </div>
    );
}

export default ListOrder;
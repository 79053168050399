import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./style.css"

const button_request = {
    fr: `Demander un devis`,
    en: `Ask for a quote`,
    sp: `Solicite una cotización`,
    de: `Fordern Sie ein Angebot an`
};
const button_show = {
    fr: `Nos références`,
    en: `Our references`,
    sp: `Nuestras referencias`,
    de: `Unsere Referenzen`
};

function SliderProd({ data }) {
    const lang = useSelector(state => state.lang);

    const [counter, setCounter] = useState(0);
    const [images, setImages] = useState([]);

    // Récupération des images du produit
    useEffect(() => {
        const fetchImg = async () => {
            if (data && data.images) {
                const reqImgProd = await Promise.all(data.images.map(async (imgId) => {
                    if (imgId.length !== 0) {
                        const req = await fetch(`https://api.pro-vide.eu/public/image/${imgId}`);
                        const res = await req.json();
                        return res ? res : '';
                    }
                    else {
                        return '';
                    }
                }));
                setImages(reqImgProd);
            }
        }
        fetchImg();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // Gestion saut à la ligne
    const transformText = (text) => {
        return text && text !== '' ? text.split('\n').map((str, index) => (
            <span key={index}>
                {str}
                <br />
            </span>
        )) : text;
    };


    return data && lang && (
        <div className='slider-products'>
            <div className="descrip">
                <h2>{data[`name_${lang}`]}</h2>
                <p>{transformText(data[`text_${lang}`])}</p>
                {data && data.table_ref.length !== 0 ?
                    <button>{button_show[lang]}</button> :
                    <button className="small">{button_request[lang]}</button>
                }
            </div>

            {images && images.length !== 0 && (
                <div className="slider-prod">
                    <div className="view">
                        <img src={images[counter].image} alt={`Illustration ${counter}`} />
                    </div>

                    <div className="list">
                        {images && images.length !== 0 && images.map((img, idx) => (
                            <div
                                key={idx}
                                onClick={() => setCounter(idx)}
                                className={`preview ${counter === idx && 'actif'}`}
                            >
                                <img src={img.image} alt="Illustration" />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default SliderProd;
import { useState } from "react";

import CreateProduct from './../../forms/products/CreateProduct';
import UpdateProduct from './../../forms/products/UpdateProduct';
import DeleteProduct from './../../forms/products/DeleteProduct';

import SelectorTypeForm from "../../components/SelectorTypeForm";

import "./style.css"

function Products() {
    const [typeForm, setTypeForm] = useState('add');

    return (
        <div className='page page_products'>
            <SelectorTypeForm type={typeForm} setType={setTypeForm} />

            {typeForm && typeForm === 'add' && <CreateProduct />}
            {typeForm && typeForm === 'edit' && <UpdateProduct />}
            {typeForm && typeForm === 'delete' && <DeleteProduct />}
        </div>
    )
}

export default Products;
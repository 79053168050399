import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import SelectorParent from "../../components/SelectorParent";

import "./style.css"
import ListOrder from "../../components/ListOrder";

function Home() {
    const realization = useSelector(state => state.data.realization);
    const categories = useSelector(state => state.data.categories);
    const products = useSelector(state => state.data.products);
    const services = useSelector(state => state.data.services);

    const [type, setType] = useState('prod');
    const [formData, setFormData] = useState({
        parentId: '',
    });

    const [listCategories, setListCategories] = useState([]);
    const [listProducts, setListProducts] = useState([]);
    const [listServices, setListServices] = useState([]);
    const [listRealization, setListRealization] = useState([]);

    useEffect(() => {
        if (!formData) {
            return
        }

        if (!formData.parentId || formData.parentId === '') {
            const tempList = categories.filter(c => !c.parentId && c.type === type);
            const catList = tempList.sort((a, b) => a.order - b.order);
            setListCategories(catList);

            setListRealization([]);
            setListProducts([]);
            setListServices([]);
            return
        }


        const tempCatList = categories.filter(c => c.parentId === formData.parentId && c.type === type);
        const catList = tempCatList.sort((a, b) => a.order - b.order);
        setListCategories(catList);

        const tempProdList = products.filter(p => p.parentId === formData.parentId);
        const prodList = tempProdList.sort((a, b) => a.order - b.order);
        setListProducts(prodList);

        const tempServList = services.filter(p => p.parentId === formData.parentId);
        const servList = tempServList.sort((a, b) => a.order - b.order);
        setListServices(servList);

        const tempRealList = realization.filter(p => p.parentId === formData.parentId);
        const realList = tempRealList.sort((a, b) => a.order - b.order);
        setListRealization(realList);

    }, [categories, products, services, realization, formData, type])

    return (
        <div className='page_home'>
            {categories && categories.length !== 0 &&
                <SelectorParent data={categories} setParentId={setFormData} type={type} setType={setType} />
            }

            {listCategories && listCategories.length !== 0 &&
                <>
                    <i className="slash"></i>
                    <ListOrder title={`Catégories ${type === 'prod' ? 'produits' : 'services'}`} list={listCategories} route='Categories' />
                </>
            }

            {listProducts && listProducts.length !== 0 &&
                <>
                    <i className="slash"></i>
                    <ListOrder title='Produits' list={listProducts} route='Products' />
                </>
            }

            {listServices && listServices.length !== 0 &&
                <>
                    <i className="slash"></i>
                    <ListOrder title='Services' list={listServices} route='Services' />
                </>
            }

            {listRealization && listRealization.length !== 0 &&
                <>
                    <i className="slash"></i>
                    <ListOrder title='Réalisations' list={listRealization} route='Realization' />
                </>
            }
        </div>
    )
}

export default Home;
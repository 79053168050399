import "./style.css";



function Textarea({ label, placeholder, value, setValue, field }) {

    // Mettre à jour la valeur
    const handleChange = (e) => {
        const value = e.target.value;

        setValue(prev => ({
            ...prev,
            [field]: value
        }));
    };

    // <Input label={} placeholder={} value={} setValue={} field={} />

    // Rendu
    return (
        <div className='fields'>
            <label>{label}</label>
            <textarea
                className={`text_${field}`}
                placeholder={placeholder}

                onChange={handleChange}
                value={value}
            />
        </div>
    );
}

export default Textarea;
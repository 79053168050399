import "./style.css";

import flag_fr from './../../assets/svg/flag-fr.svg'
import flag_en from './../../assets/svg/flag-en.svg'
import flag_sp from './../../assets/svg/flag-es.svg'
import flag_de from './../../assets/svg/flag-de.svg'
import ArrayReferences from "../ArrayReferences";

function References({ lastData, formData, setFormData }) {
    
    const handleToChange = (e, field) => {
        setFormData({ ...formData, [field]: e.target.value });
    }

    return (
        <div className='sect_references'>
            <ArrayReferences lastData={lastData} formData={formData} setFormData={setFormData} />
            
            <div className="container_descrip_ref">
                <div className="descrip_ref">
                    <label>Description du tableau (fr) <img src={flag_fr} alt="_" /></label>
                    <textarea placeholder="Description du tableau" value={formData.ref_descrip_fr} onChange={(e) => handleToChange(e, 'ref_descrip_fr')}></textarea>
                </div>

                <div className="descrip_ref">
                    <label>Description du tableau (en) <img src={flag_en} alt="_" /></label>
                    <textarea placeholder="Description du tableau" value={formData.ref_descrip_en} onChange={(e) => handleToChange(e, 'ref_descrip_en')}></textarea>
                </div>

                <div className="descrip_ref">
                    <label>Description du tableau (sp) <img src={flag_sp} alt="_" /></label>
                    <textarea placeholder="Description du tableau" value={formData.ref_descrip_sp} onChange={(e) => handleToChange(e, 'ref_descrip_sp')}></textarea>
                </div>

                <div className="descrip_ref">
                    <label>Description du tableau (de) <img src={flag_de} alt="_" /></label>
                    <textarea placeholder="Description du tableau" value={formData.ref_descrip_de} onChange={(e) => handleToChange(e, 'ref_descrip_de')}></textarea>
                </div>
            </div>
        </div>
    );
}

export default References;
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addCategoriesToState } from "../../../redux";

import SelectorCategory from "../../../components/SelectorCategory";
import NameAndText from "../../../components/NameAndText";
import InputImage from "../../../components/InputImage";

import "./style.css"

function UpdateCategorie() {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const categories = useSelector(state => state.data.categories);

    const [categoryId, setCategoryId] = useState('');
    const [lastData, setLastData] = useState(null);
    const [formData, setFormData] = useState({
        parentId: '',

        name_fr: '',
        name_en: '',
        name_sp: '',
        name_de: '',

        text_fr: '',
        text_en: '',
        text_sp: '',
        text_de: '',

        image: ''
    });

    // Recup des données de la catégorie selectionné
    useEffect(() => {
        if (!categoryId) {
            setLastData(null);
            setFormData({
                parentId: '',

                name_fr: '',
                name_en: '',
                name_sp: '',
                name_de: '',

                text_fr: '',
                text_en: '',
                text_sp: '',
                text_de: '',

                image: ''
            });
            return
        }

        const categoryToUpdate = categories.find(cat => cat._id === categoryId);
        setLastData(categoryToUpdate);
        setFormData({ ...categoryToUpdate, image: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId])

    const updateCategory = async () => {
        if (!categoryId || formData.name_fr === '') {
            return
        }

        const bodyReq = {
            name_fr: formData.name_fr,
            online: true,
        };

        if (formData.name_en !== '') {
            bodyReq.name_en = formData.name_en;
        }
        if (formData.name_sp !== '') {
            bodyReq.name_sp = formData.name_sp;
        }
        if (formData.name_de !== '') {
            bodyReq.name_de = formData.name_de;
        }
        if (formData.text_fr !== '') {
            bodyReq.text_fr = formData.text_fr;
        }
        if (formData.text_en !== '') {
            bodyReq.text_en = formData.text_en;
        }
        if (formData.text_sp !== '') {
            bodyReq.text_sp = formData.text_sp;
        }
        if (formData.text_de !== '') {
            bodyReq.text_de = formData.text_de;
        }

        if (formData.image !== '') {
            if (lastData.image && lastData.image !== '') {
                const req = await fetch(`https://api.pro-vide.eu/admin/img/${lastData.image}`, {
                    method: 'DELETE',
                    headers: { 'Authorization': `Bearer ${user.token}` }
                });
                const res = await req.json();
                if (!res) {
                    alert('Impossible de supprimer l\'ancienne image de la catégorie');
                    return
                }
            }

            const bodyImg = new FormData();
            bodyImg.append('image', formData.image);

            const req = await fetch(`https://api.pro-vide.eu/admin/img`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${user.token}`
                },
                body: bodyImg
            });
            const res = await req.json();

            if (res) {
                bodyReq.image = res;

                const reqCat = await fetch(`https://api.pro-vide.eu/admin/categories/${lastData._id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}`
                    },
                    body: JSON.stringify(bodyReq)
                });
                const resCat = await reqCat.json();
                if (resCat && resCat.length !== 0) {
                    dispatch(addCategoriesToState(resCat));
                    alert('La catégorie a bien été modifié');
                }
            }
            alert('Impossible d\'ajouter la nouvelle image');
            return
        }

        const reqCat = await fetch(`https://api.pro-vide.eu/admin/categories/${lastData._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(bodyReq)
        });
        const resCat = await reqCat.json();

        if (resCat && resCat.length !== 0) {
            dispatch(addCategoriesToState(resCat));
            alert('La catégorie a bien été modifier');
            return
        }
        alert('Erreur lors de la modification de la catégorie');
    }

    return (
        <div className='form_container form_updateCategorie'>
            <i className="slash"></i>

            {categories && categories.length !== 0 &&
                <SelectorCategory allCategories={categories} setId={setCategoryId} />
            }

            <i className="slash"></i>

            <h2>Modifier une catégorie</h2>

            {lastData &&
                <section className="sect_nameAndText_multiLang">
                    <NameAndText lang='fr' holder='de la catégorie' nameValue={formData.name_fr} textValue={formData.text_fr} setFormData={setFormData} />
                    <NameAndText lang='en' holder='de la catégorie' nameValue={formData.name_en} textValue={formData.text_en} setFormData={setFormData} />
                    <NameAndText lang='sp' holder='de la catégorie' nameValue={formData.name_sp} textValue={formData.text_sp} setFormData={setFormData} />
                    <NameAndText lang='de' holder='de la catégorie' nameValue={formData.name_de} textValue={formData.text_de} setFormData={setFormData} />
                </section>
            }

            {lastData &&
                <InputImage lastImage={lastData?.image} setFormData={setFormData} />
            }

            <button onClick={() => updateCategory()} className="submitForm black">Modifier la catégorie</button>
        </div>
    )
}

export default UpdateCategorie;
import { useEffect, useState } from 'react';

import icon_picture from './../../assets/svg/icon_picture.svg';
import btn_reload from './../../assets/svg/button_reload.svg';

import "./style.css";

function InputImage({ lastImage, formData, setFormData }) {
    const [preview, setPreview] = useState(null);
    const [reload, setReload] = useState(0);

    // Affichage d'une potentiel pre-image
    useEffect(() => {
        setPreview(null);
        setFormData(prev => {
            return {
                ...prev,
                image: ''
            }
        });

        const fetchLastImage = async () => {
            const req = await fetch(`https://api.pro-vide.eu/public/image/${lastImage}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });
            const res = await req.json();

            if (res && res.image) {
                setPreview(res.image);
            }
        }

        if (lastImage) {
            fetchLastImage();
        }


    }, [lastImage, reload])

    useEffect(() => {
        if (formData && formData.image === '') {
            setPreview(null);
        }
    }, [formData])

    // Mettre à jour la valeur & afficher la preview
    const handleChange = (e) => {
        const file = e.target.files[0];

        if (!file) {
            setPreview(null);
            setFormData(prev => ({
                ...prev,
                image: ''
            }));
            return
        }

        // Créer une URL temporaire pour afficher la prévisualisation
        const previewUrl = URL.createObjectURL(file);
        setPreview(previewUrl);

        setFormData(prev => ({
            ...prev,
            image: file
        }));
    };

    // Rendu
    return (
        <div className='container'>
            {lastImage &&
                <img
                    className='btn_reload'
                    src={btn_reload} alt=""
                    onClick={() => setReload(p => p + 1)}
                />
            }
            <div className='input_img'>
                {!preview && <img className='icon' src={icon_picture} alt="_" />}
                {!preview && <label>Ajouter une image</label>}
                {preview && <img className='preview' src={preview} alt='Non supporté' />}
                <input
                    type="file"
                    onChange={(e) => handleChange(e)}
                />
            </div>
        </div>
    );
}

export default InputImage;
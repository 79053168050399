import { useState } from "react";

import CreateRealization from './../../forms/realization/CreateRealization';
import UpdateRealization from './../../forms/realization/UpdateRealization';
import DeleteRealization from './../../forms/realization/DeleteRealization';

import SelectorTypeForm from "../../components/SelectorTypeForm";

import "./style.css"

function Realization() {
    const [typeForm, setTypeForm] = useState('add');

    return (
        <div className='page page_realization'>
            <SelectorTypeForm type={typeForm} setType={setTypeForm} />

            {typeForm && typeForm === 'add' && <CreateRealization />}
            {typeForm && typeForm === 'edit' && <UpdateRealization />}
            {typeForm && typeForm === 'delete' && <DeleteRealization />}
        </div>
    )
}

export default Realization;
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import SliderProd from '../SliderProd';
import Banner from './../Banner';

import VerifReferences from '../VerifReferences';
import Description from '../Description';
import Download from '../Download';
import Accessories from '../Accessories';

import icon_contact from './../../assets/svg/contact_icon-product.svg'
import icon_phone from './../../assets/svg/phone_icon-product.svg'
import icon_message from './../../assets/svg/message_icon-product.svg'

import "./style.css";

const title_list_features = {
    fr: `Caracteristiques`,
    en: `Features`,
    sp: `Características`,
    de: `Merkmale`
};
const title_list_benefits = {
    fr: `Avantages`,
    en: `Benefits`,
    sp: `Ventajas`,
    de: `Vorteile`
};

const button_references = {
    fr: `Nos références`,
    en: `Our references`,
    sp: `Nuestras referencias`,
    de: `Unsere Referenzen`
};
const button_description = {
    fr: `Description`,
    en: `Description`,
    sp: `Descripción`,
    de: `Beschreibung`
};
const button_downloads = {
    fr: `Téléchargements`,
    en: `Downloads`,
    sp: `Descargas`,
    de: `Downloads`
};
const button_accessories = {
    fr: `Accessoires`,
    en: `Accessories`,
    sp: `Accesorios`,
    de: `Zubehör`
};
const msg_contact = {
    fr: `Si vous désirez une offre, ou de plus amples informations sur ce produit contactez-nous :`,
    en: `If you would like an offer, or more information on this product, contact us :`,
    sp: `Si desea una oferta o más información sobre este producto, contáctenos :`,
    de: `Wenn Sie ein Angebot oder weitere Informationen zu diesem Produkt wünschen, kontaktieren Sie uns :`
};
const button_contact = {
    fr: `Nous écrire`,
    en: `Write us`,
    sp: `Escribenos`,
    de: `Schreiben Sie uns`
};

function VerifProduct({ data, setCounter }) {
    const lang = useSelector(state => state.lang);
    const user = useSelector(state => state.user);

    const schemaRef = useRef(null);

    const [modal, setModal] = useState('references');
    const [plans, setPlans] = useState([]);

    const [schema, setSchema] = useState({
        id: '',
        url: '',
        small: ''
    });

    // Vérification tableau ref
    useEffect(() => {
        if (data && data.table_ref.length === 0) {
            setModal('descrip');
        }
        else {
            setModal('references');
        }
    }, [data]);

    // ------------------------------------------

    // Récup des plans/pdf
    useEffect(() => {
        if (!data || !data.files || data.files.length === 0) {
            setPlans([]);
            return
        }

        const fetchPlans = async () => {
            let newArray = [];

            for (let fileId of data.files) {
                const req = await fetch(`https://api.pro-vide.eu/pdf/${fileId}`);
                const res = await req.json();

                if (res && res.pdf) {
                    newArray.push({ url: res.pdf, filename: getFileName(res.pdf) });
                }
                else {
                    const req2 = await fetch(`https://api.pro-vide.eu/public/plans/${fileId}`);
                    const res2 = await req2.json();

                    newArray.push(res2 && res2.plan ? { url: res2.plan, filename: getFileName(res2.plan) } : '');
                }
            }

            setPlans(newArray);
        }
        fetchPlans();
    }, [data]);

    // Ancre schema
    useEffect(() => {
        if (schema.id && schema.id !== '' && schemaRef.current) {
            schemaRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [schema]);

    // Découpage filename
    const getFileName = (url) => {
        if (!url) return '';

        let filename = url.substring(url.lastIndexOf('/') + 1);
        filename = filename.replace(/(-[^-]+)(?=\.)/g, '');
        return filename;
    };

    const next = () => {
        window.scrollTo(0, 0);
        setCounter(c => {
            return {
                products: c.products + 1,
                services: c.services,
                realizations: c.realizations
            }
        })
    }

    const setOnline = async () => {
        const req = await fetch(`https://api.pro-vide.eu/admin/products/${data._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ online: true })
        });
        const res = await req.json();

        if (res && res.length !== 0) {
            next();
        }
    }

    return data && (
        <div className='verif_product'>
            <div className="container-slider-banner">
                <SliderProd data={data} />
                <Banner />
            </div>

            {data && ((data[`benefits_${lang}`].length !== 0 || data[`features_${lang}`].length !== 0) || (schema && schema.url !== '')) && (
                <div className="container-schema-descriptions" ref={schemaRef}>
                    {schema && schema.url !== '' && (
                        <div id={`shema-${schema.id}`} className="container-schema">
                            <img src={schema.url} alt="Schema" />
                        </div>
                    )}

                    {(data[`benefits_${lang}`].length !== 0 || data[`features_${lang}`].length !== 0) && (
                        <div className={`container-descriptions ${(!schema || schema.url === '') && 'no-schema'}`}>
                            {data[`features_${lang}`].length !== 0 && (
                                <ul className="features">
                                    <h3>{title_list_features[lang]}</h3>
                                    {data[`features_${lang}`].map((feature, idx) => (
                                        <li key={idx}>{feature}</li>
                                    ))}
                                </ul>
                            )}

                            {data[`benefits_${lang}`].length !== 0 && (
                                <ul className="benefits">
                                    <h3>{title_list_benefits[lang]}</h3>
                                    {data[`benefits_${lang}`].map((benefit, idx) => (
                                        <li key={idx}>{benefit}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}
                </div>
            )}

            {data &&
                <div className="modal-product">
                    <ul className="menu">
                        {data.table_ref.length !== 0 && <li><button className={`${modal === 'references' && 'actif'}`} onClick={() => setModal('references')}>{button_references[lang]}</button></li>}
                        <li><button className={`${modal === 'descrip' && 'actif'}`} onClick={() => setModal('descrip')}>{button_description[lang]}</button></li>
                        <li><button className={`${modal === 'download' && 'actif'}`} onClick={() => setModal('download')}>{button_downloads[lang]}</button></li>
                        <li><button className={`${modal === 'accessories' && 'actif'}`} onClick={() => setModal('accessories')}>{button_accessories[lang]}</button></li>
                    </ul>

                    {modal && modal === 'references' && <VerifReferences data={data} schema={schema} setSchema={setSchema} />}
                    {modal && modal === 'descrip' && <Description data={data[`text_${lang}`]} />}
                    {modal && modal === 'download' && <Download data={plans} />}
                    {modal && modal === 'accessories' && <Accessories data={data?.associated} />}
                </div>
            }

            <div className="contact">
                <p><img src={icon_contact} alt="Icon" />{msg_contact[lang]}</p>

                <div className="contact-btn">
                    <a href="tel:+330255996505" className="btn-phone"><img src={icon_phone} alt="Icon" />+33 2 55 99 65 05</a>
                    <button className="btn-email"><img src={icon_message} alt="Icon" />{button_contact[lang]}</button>
                </div>
            </div>

            <div className="container_btn_validator">
                <button onClick={() => next()} className='button_next'>Suivant</button>
                <button onClick={() => setOnline()} className='button_valid'>Valider</button>
            </div>
        </div>
    );
}

export default VerifProduct;
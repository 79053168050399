import add_icon from './../../assets/svg/add_icon.svg';
import edit_icon from './../../assets/svg/edit_icon.svg';
import delete_icon from './../../assets/svg/delete_icon.svg';

import "./style.css";

function Selector_typeForm({ type, setType }) {
    return (
        <div className='selector_typeForm'>            
            <button 
                className={type === 'add' ? 'btn_add btn_add_actif' : 'btn_add'} 
                onClick={() => setType('add')}
            >
                <img src={add_icon} alt="_" />
                Ajouter
            </button>
            
            <button 
                className={type === 'edit' ? 'btn_edit btn_edit_actif' : 'btn_edit'} 
                onClick={() => setType('edit')}
            >
                <img src={edit_icon} alt="_" />
                Modifier
            </button>
            
            <button 
                className={type === 'delete' ? 'btn_delete btn_delete_actif' : 'btn_delete'} 
                onClick={() => setType('delete')}
            >
                <img src={delete_icon} alt="_" />
                Supprimer
            </button>
        </div>
    );
}

export default Selector_typeForm;
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addCategoriesToState } from "../../../redux";

import SelectorParent from "../../../components/SelectorParent";
import ListToDelete from "../../../components/ListToDelete";

import "./style.css"

function DeleteCategorie() {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const categories = useSelector(state => state.data.categories);
    
    const [listToDelete, setListToDelete] = useState([]);
    const [toDelete, setToDelete] = useState('');
    const [type, setType] = useState('prod');
    
    // eslint-disable-next-line no-unused-vars
    const [formData, setFormData] = useState({
        parentId: ''
    });

    useEffect(() => {
        const deleteCategory = async (catId) => {
            const req = await fetch(`https://api.pro-vide.eu/admin/categories/${catId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const res = await req.json();
            
            if (res === true) {
                setToDelete('');
                alert('La catégorie a bien été supprimé');
                dispatch(addCategoriesToState(categories.filter(d => d._id !== catId)));
                return
            }
            alert('Erreur lors de la supprésion de la catégorie');
        }

        if (toDelete && toDelete !== '') {
            deleteCategory(toDelete);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDelete])

    return (
        <div className='form_container form_deleteCategorie'>
        
            <i className="slash"></i>

            {categories && categories.length !== 0 &&
                <SelectorParent data={categories} setParentId={setFormData} type={type} setType={setType} setListToDelete={setListToDelete} />
            }

            <i className="slash"></i>

            <h2>Supprimer une catégorie</h2>

            {listToDelete && listToDelete.length !== 0 && 
                <ListToDelete data={listToDelete} setToDelete={setToDelete} type={type} />
            }
        </div>
    )
}

export default DeleteCategorie;
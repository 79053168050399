import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux'
import { addCategoriesToState, addProductsToState, addRealizationToState, addServicesToState, login, logout } from './../../redux'

import logo_provide from './../../assets/img/logo-pro-vide.png';

import categories_icon from './../../assets/svg/categories_icon.svg';
import products_icon from './../../assets/svg/products_icon.svg';
import services_icon from './../../assets/svg/services_icon.svg';
import realization_icon from './../../assets/svg/realization_icon.svg';

import "./style.css"

function Header() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);

    // Verifie si l'utilisateur est connecté
    useEffect(() => {
        const admin_id = sessionStorage.getItem('admin_id');
        const admin_pw = sessionStorage.getItem('admin_pw');
        const token = sessionStorage.getItem('token');

        console.log(token);
        if (!admin_id || !admin_pw || !token) {
            dispatch(logout());
            navigate('/login');
            return
        }

        const reloadToken = async () => {
            const req = await fetch(`https://api.pro-vide.eu/admin/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    admin_id: admin_id,
                    admin_pw: admin_pw
                })
            });
            const res = await req.json();

            if (!res || !res.token) {
                dispatch(logout());
                navigate('/login');
                return
            }

            sessionStorage.setItem('token', res.token);

            dispatch(login({
                admin_id: admin_id,
                admin_pw: admin_pw,
                token: res.token
            }));            
        }

        reloadToken();

    }, [dispatch, navigate]);

    // Récuperation des données pro-vide
    useEffect(() => {
        if (!user || user.token === '') {
            dispatch(addCategoriesToState([]));
            dispatch(addProductsToState([]));
            dispatch(addServicesToState([]));
            dispatch(addRealizationToState([]));
            return
        }

        const fetchData = async () => {
            // Recup des catégories
            const reqCategories = await fetch(`https://api.pro-vide.eu/admin/categories`, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${user.token}` }
            });
            const resCategories = await reqCategories.json();
            
            const catList = resCategories.sort((a, b) => a.order - b.order);
            dispatch(addCategoriesToState(catList));

            // Recup des produits
            const reqProducts = await fetch(`https://api.pro-vide.eu/admin/products`, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${user.token}` }
            });
            const resProducts = await reqProducts.json();
            
            const prodList = resProducts.sort((a, b) => a.order - b.order);
            dispatch(addProductsToState(prodList));

            // Recup des services
            const reqServices = await fetch(`https://api.pro-vide.eu/admin/services`, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${user.token}` }
            });
            const resServices = await reqServices.json();
            
            const servList = resServices.sort((a, b) => a.order - b.order);
            dispatch(addServicesToState(servList));

            // Recup des réalisations
            const reqRealization = await fetch(`https://api.pro-vide.eu/admin/realization`, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${user.token}` }
            });
            const resRealization = await reqRealization.json();
            
            const realList = resRealization.sort((a, b) => a.order - b.order);
            dispatch(addRealizationToState(realList));
        }
        fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // Récuperation du nom/route de la page
    function getPageName() {
        const pathname = window.location.pathname;
        const pageName = pathname.split("/").pop();
        return pageName;
    }

    // Changement de page
    function goPage(route) {
        window.scrollTo(0, 0);
        navigate(`/${route}`);
    }

    const pageName = getPageName();

    return user && user.token !== '' && (
        <header>
            <div className="header_content">
                <Link to='/' className="logo_title">
                    <img src={logo_provide} alt="_" />
                    <h1>Pro-vide<span>Créateur de solution</span></h1>
                </Link>

                <nav>
                    <button className={pageName === 'categories' ? 'actif' : ''} onClick={() => goPage('categories')}><img src={categories_icon} alt="_" />categories</button>
                    <button className={pageName === 'products' ? 'actif' : ''} onClick={() => goPage('products')}><img src={products_icon} alt="_" />produits</button>
                    <button className={pageName === 'services' ? 'actif' : ''} onClick={() => goPage('services')}><img className='top' src={services_icon} alt="_" />services</button>
                    <button className={pageName === 'realization' ? 'actif' : ''} onClick={() => goPage('realization')}><img src={realization_icon} alt="_" />réalisation</button>
                </nav>

                <button onClick={() => goPage('verif')} className='btn_verifData'>Vérifier les données</button>
            </div>
        </header>
    )
}

export default Header;
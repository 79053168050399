import { useEffect, useState } from "react";

import chevron_left from './../../assets/svg/chevron_left.svg'
import chevron_right from './../../assets/svg/chevron_right.svg'
import btn_delete from './../../assets/svg/btn_close.svg'

import "./style.css";

function ArrayReferences({ lastData, formData, setFormData }) {
    const [headRef, setHeadRef] = useState(Array(5).fill(''));
    const [schemas, setSchemas] = useState(Array(5).fill(''));
    const [arrayData, setArrayData] = useState([
        Array(6).fill(''),
        Array(6).fill(''),
        Array(6).fill(''),
        Array(6).fill(''),
        Array(6).fill(''),
    ]);

    useEffect(() => {
        if (!lastData) {
            return;
        }

        if (lastData.table_ref.length === 0) {
            setHeadRef(Array(5).fill(''));
            setSchemas(Array(5).fill(''));
            setArrayData([
                Array(6).fill(''),
                Array(6).fill(''),
                Array(6).fill(''),
                Array(6).fill(''),
                Array(6).fill(''),
            ]);
            return;
        }

        const fetchSchemas = async () => {
            const schemaArray = await Promise.all(
                lastData.table_ref.slice(1).map(async (ref) => {
                    if (ref[1] === '') {
                        return '';
                    }
    
                    try {
                        const req = await fetch(`https://api.pro-vide.eu/public/image/${ref[1]}`);
                        const res = await req.json();
                        
                        return res && res._id ? { name: res.image.split('/').pop() } : '';
                    } 
                    catch {
                        return '';
                    }
                })
            );


            return schemaArray;
        }

        const fetchLastData = async () => {
            const newHead = lastData.table_ref[0].slice(2);

            const newArray = lastData.table_ref.slice(1).map(ref => {
                return ref.filter((_, index) => index !== 1);
            });

            const schemaArray = await fetchSchemas();

            setHeadRef(newHead);
            setArrayData(newArray);
            setSchemas(schemaArray);
        }

        fetchLastData();
    }, [lastData]);


    useEffect(() => {
        if (!arrayData || !schemas || !headRef) {
            return
        }

        let newArray = [['References', '3D-plan']];

        for (let head of headRef) {
            newArray[0].push(head);
        }

        // eslint-disable-next-line no-unused-vars
        for (let data of arrayData) {
            newArray.push([]);
        }

        for (let i = 0; i < arrayData.length; i++) {
            for (let j = 0; j < arrayData[i].length; j++) {

                newArray[i + 1].push(arrayData[i][j])

                if (j === 0) {
                    newArray[i + 1].push(schemas[i])
                }
            }
        }

        setFormData({ ...formData, table_ref: newArray });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [arrayData, schemas, headRef]);

    const handleToChange = (row, col, e) => {
        const value = e.target.value;

        let newArray = [...arrayData];
        newArray[row][col] = value;

        setArrayData(newArray);
    }

    const handleHeadToChange = (id, e) => {
        const value = e.target.value;

        let newArray = [...headRef];
        newArray[id] = value;

        setHeadRef(newArray);
    }

    const handleFileToChange = (id, e) => {
        const value = e.target.files[0];

        let newArray = [...schemas];
        newArray[id] = value;
        setSchemas(newArray);
    }

    const deleteSchema = async (idx) => {
        let newSchema = [...schemas];
        newSchema[idx] = '';

        setSchemas(newSchema);
    }

    const delRow = () => {
        if (schemas.length <= 2) {
            return
        }

        let newShemas = [...schemas];
        newShemas.pop();

        let newArray = [...arrayData];
        newArray.pop();

        setSchemas(newShemas);
        setArrayData(newArray);
    }

    const addRow = () => {
        setSchemas([...schemas, null]);

        let newArray = [...arrayData, Array(arrayData[0].length).fill('')];

        setArrayData(newArray);
    }

    const delColumn = () => {
        if (headRef.length <= 1) {
            return
        }

        let newHeadRef = [...headRef];
        newHeadRef.pop();

        let newArray = [...arrayData];

        for (let data of newArray) {
            data.pop();
        }

        setHeadRef(newHeadRef);
        setArrayData(newArray);
    }

    const addColumn = () => {
        let newHeadRef = [...headRef, ''];
        setHeadRef(newHeadRef);

        let newArray = [...arrayData];

        for (let data of newArray) {
            data.push('');
        }

        setArrayData(newArray);
    }

    const switchLeft = (id) => {
        if (id === 0) {
            return
        }

        let newHead = [...headRef];
        const tempHeadValue = newHead[id];

        newHead[id] = newHead[id - 1];
        newHead[id - 1] = tempHeadValue;

        let newArray = [...arrayData];

        for (let i = 0; i < newArray.length; i++) {
            const tempArrayValue = newArray[i][id + 1];
            newArray[i][id + 1] = newArray[i][id];
            newArray[i][id] = tempArrayValue;
        }

        setArrayData(newArray);
        setHeadRef(newHead);
    }

    const switchRight = (id) => {
        if (id === headRef.length - 1) {
            return
        }

        let newHead = [...headRef];
        const tempHeadValue = newHead[id];

        newHead[id] = newHead[id + 1];
        newHead[id + 1] = tempHeadValue;

        let newArray = [...arrayData];

        for (let i = 0; i < newArray.length; i++) {
            const tempArrayValue = newArray[i][id + 2];
            newArray[i][id + 2] = newArray[i][id + 1];
            newArray[i][id + 1] = tempArrayValue;
        }

        setArrayData(newArray);
        setHeadRef(newHead);
    }

    const switchTop = (id) => {
        if (id === 0) {
            return
        }

        let newSchemas = [...schemas];
        const tempSchemaValue = newSchemas[id];

        newSchemas[id] = newSchemas[id - 1];
        newSchemas[id - 1] = tempSchemaValue;

        let newArray = [...arrayData];

        const tempArrayValue = newArray[id];
        newArray[id] = newArray[id - 1];
        newArray[id - 1] = tempArrayValue;

        setArrayData(newArray);
        setSchemas(newSchemas);
    }

    const switchBottom = (id) => {
        if (id === schemas.length - 1) {
            return
        }

        let newSchemas = [...schemas];
        const tempSchemaValue = newSchemas[id];

        newSchemas[id] = newSchemas[id + 1];
        newSchemas[id + 1] = tempSchemaValue;

        let newArray = [...arrayData];

        const tempArrayValue = newArray[id];
        newArray[id] = newArray[id + 1];
        newArray[id + 1] = tempArrayValue;

        setArrayData(newArray);
        setSchemas(newSchemas);
    }

    return arrayData && headRef && schemas && (
        <div className='array_references'>
            <div className="editor">
                <div className="edit edit_row">
                    <button onClick={() => delRow()} className="del">-</button>
                    <span>Ligne</span>
                    <button onClick={() => addRow()} className="add">+</button>
                </div>

                <h3>Tableau de références</h3>

                <div className="edit edit_column">
                    <button onClick={() => delColumn()} className="del">-</button>
                    <span>Colonne</span>
                    <button onClick={() => addColumn()} className="add">+</button>
                </div>
            </div>

            <div className="array">
                <div className="row">
                    <i className="hiddenCol"></i>
                    <i className="hidden"></i>
                    <i className="hidden"></i>

                    {headRef.map((_, id) =>
                        <div key={id} className="edit_table edit_column">
                            <img
                                className="edit_left"
                                src={chevron_left} alt="_"
                                onClick={() => switchLeft(id)}
                            />
                            <img
                                className="edit_right"
                                src={chevron_right} alt="_"
                                onClick={() => switchRight(id)}
                            />
                        </div>
                    )}
                </div>

                <div className="row">
                    <i className="hiddenCol"></i>
                    <input className='input_text' type="text" value='3D-plan' readOnly />
                    <input className='input_text' type="text" value='References' readOnly />

                    {headRef.map((head, id) =>
                        <input className='input_text' key={id} type="text" value={head} onChange={(e) => handleHeadToChange(id, e)} placeholder="en-tête" />
                    )}
                </div>

                {arrayData.map((row, idx) =>
                    <div key={idx} className="row">
                        <div key={idx} className="edit_table edit_row">
                            <img
                                className="edit_top"
                                src={chevron_left} alt="_"
                                onClick={() => switchTop(idx)}
                            />
                            <img
                                className="edit_bottom"
                                src={chevron_right} alt="_"
                                onClick={() => switchBottom(idx)}
                            />
                        </div>

                        <div className="input_file">
                            <input type="file" onChange={(e) => handleFileToChange(idx, e)} />
                            <label>{schemas[idx] && schemas[idx].name ? schemas[idx].name : '+ Ajouter un schema'}</label>

                            {schemas[idx] && schemas[idx].name && 
                                <img onClick={() => deleteSchema(idx)} className="dlt_schema" src={btn_delete} alt="_" />
                            }

                        </div>

                        {row.map((inp, id) =>
                            <input className='input_text' key={id} type="text" value={inp} onChange={(e) => handleToChange(idx, id, e)} placeholder={id === 0 ? 'référence' : 'value'} />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ArrayReferences;
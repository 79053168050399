import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProductsToState } from './../../../redux';

import SelectorParent from "../../../components/SelectorParent";
import ListToDelete from "../../../components/ListToDelete";

import "./style.css"

function DeleteProduct() {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const products = useSelector(state => state.data.products);
    const categories = useSelector(state => state.data.categories);

    const [listToDelete, setListToDelete] = useState([]);
    const [toDelete, setToDelete] = useState('');

    const [formData, setFormData] = useState({
        parentId: ''
    });

    useEffect(() => {
        if (!formData) {
            return
        }

        if (formData.parentId === '') {
            setListToDelete([]);
            return
        }

        const productsList = products.filter(prod => prod.parentId === formData.parentId);
        setListToDelete(productsList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, products])

    useEffect(() => {
        if (!toDelete || toDelete === '') {
            return
        }

        const deleteProduct = async () => {
            const req = await fetch(`https://api.pro-vide.eu/admin/products/${toDelete}`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${user.token}` }
            });
            const res = await req.json();

            console.log(res);
            if (res === true) {
                const newProductList = products.filter(prod => prod._id !== toDelete);
                dispatch(addProductsToState(newProductList));
                alert('Le produit a bien été supprimé');
                setToDelete('');
                return
            }
            alert('Erreur lors de la suppression du produit');
        }

        deleteProduct();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDelete])

    return (
        <div className='form_container form_deleteProduct'>

            <i className="slash"></i>

            {categories && categories.length !== 0 &&
                <SelectorParent data={categories} setParentId={setFormData} type='prod' />
            }

            <i className="slash"></i>

            <h2>Supprimer un produit</h2>

            {listToDelete && listToDelete.length !== 0 &&
                <ListToDelete data={listToDelete} setToDelete={setToDelete} />
            }
        </div>
    )
}

export default DeleteProduct;
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addServicesToState } from "../../../redux";

import SelectorService from "../../../components/SelectorService";
import NameAndText from "../../../components/NameAndText";
import MultiInputImage from "../../../components/MultiInputImage";
import Input from "../../../components/Input";

import icon_delete from './../../../assets/svg/btn_close.svg';
import icon_reload from './../../../assets/svg/button_reload.svg';

import "./style.css"

function UpdateService() {
    const dispatch = useDispatch();

    const categories = useSelector(state => state.data.categories);
    const services = useSelector(state => state.data.services);
    const user = useSelector(state => state.user);

    const [serviceSelected, setServiceSelected] = useState(null);
    const [lastData, setLastData] = useState(null);
    const [reload, setReload] = useState(0);

    const [filename, setFilename] = useState(null);
    const [formData, setFormData] = useState({
        parentId: '',

        name_fr: '',
        name_en: '',
        name_sp: '',
        name_de: '',

        text_fr: '',
        text_en: '',
        text_sp: '',
        text_de: '',

        price: '',
        flyer: '',
        video: '',
        images: Array(9).fill(null)
    });

    // Recup des données de la catégorie selectionné
    useEffect(() => {
        if (!serviceSelected) {
            setLastData(null);
            setFormData({
                parentId: '',

                name_fr: '',
                name_en: '',
                name_sp: '',
                name_de: '',

                text_fr: '',
                text_en: '',
                text_sp: '',
                text_de: '',

                price: '',
                flyer: '',
                video: '',

                images: Array(9).fill(null)
            });
            return
        }

        const fetchLastData = async () => {
            let resFlyer = '';

            if (serviceSelected.flyer && serviceSelected.flyer !== '') {
                const req = await fetch(`https://api.pro-vide.eu/public/plan/${serviceSelected.flyer}`);
                resFlyer = await req.json();
            }

            setFormData({ ...serviceSelected, flyer: '', images: Array(9).fill(null) });

            if (resFlyer && resFlyer.plan) {
                setFilename(resFlyer.plan.split('/').pop());
                setLastData({
                    ...serviceSelected,
                    flyer: resFlyer.plan.split('/').pop()
                });
                return
            }

            setLastData({ ...serviceSelected, flyer: '' });
            setFilename('');
        }

        fetchLastData();
    }, [serviceSelected, reload])


    const handleChangeFile = (e) => {
        const file = e.target.files[0];

        setFilename(file.name)
        setFormData({
            ...formData,
            flyer: file ? file : ''
        });
    }

    const updateImages = async (i) => {
        const newImage = formData.images[i];
        const lastImage = lastData.images[i];

        // On ajoute rien
        if (newImage === null && !lastImage) {
            return false
        }

        // On ajoute l'ancien image_id
        if (newImage !== null && !newImage.name && lastImage) {
            return lastImage
        }

        // Add / Update
        if (newImage && newImage.name) {
            if (lastImage) {
                const req = await fetch(`https://api.pro-vide.eu/admin/img/${lastImage}`, {
                    method: 'DELETE',
                    headers: { 'Authorization': `Bearer ${user.token}` }
                });
                const res = await req.json();

                if (res !== true) {
                    alert(`Impossible de supprimer l'ancienne image n°${i + 1}`);
                    return 'error'
                }
            }

            const body = new FormData();
            body.append('image', newImage);

            const req = await fetch(`https://api.pro-vide.eu/admin/img`, {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${user.token}` },
                body: body
            });
            const res = await req.json();

            if (res) {
                return res;
            }
            alert(`Impossible d'ajouter l'image n°${i + 1}`);
            return 'error'
        }

        // Delete
        if (newImage === null && lastImage) {
            const req = await fetch(`https://api.pro-vide.eu/admin/img/${lastImage}`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${user.token}` }
            });
            const res = await req.json();

            if (res !== true) {
                alert(`Impossible de supprimer l'ancienne image n°${i + 1}`);
                return 'error'
            }
        }
    }

    const updateFlyer = async () => {
        // No change
        if (lastData.flyer === filename) {
            return false
        }

        // Delete
        if (filename === '' && lastData.flyer && lastData.flyer !== '') {
            const req = await fetch(`https://api.pro-vide.eu/admin/plan/${serviceSelected.flyer}`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${user.token}` }
            });
            const res = await req.json();

            if (res !== true) {
                alert('Impossible de supprimer le flyer');
                return 'error'
            }
            return ''
        }

        // Add
        if (formData.flyer.name && (!serviceSelected.flyer || serviceSelected.flyer === '')) {
            const body = new FormData();
            body.append('plan', formData.flyer);

            const req = await fetch(`https://api.pro-vide.eu/admin/plan`, {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${user.token}` },
                body: body
            });
            const res = await req.json();

            if (res) {
                return res;
            }
            alert('Impossible d\'ajouter le nouveau flyer');
            return 'error'
        }

        // Update
        if (lastData.flyer && lastData.flyer !== '' && lastData.flyer !== filename && formData.flyer.name) {
            const delFlyer = await fetch(`https://api.pro-vide.eu/admin/plan/${serviceSelected.flyer}`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${user.token}` }
            });
            const resDel = await delFlyer.json();

            if (resDel !== true) {
                alert('Impossible de supprimer l\'ancien flyer');
                return 'error'
            }

            // -----------------------------------------------------------

            const body = new FormData();
            body.append('plan', formData.flyer);

            const req = await fetch(`https://api.pro-vide.eu/admin/plan`, {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${user.token}` },
                body: body
            });
            const res = await req.json();

            if (res) {
                return res;
            }
            alert('Impossible d\'ajouter le nouveau flyer');
            return 'error'
        }

        return false
    }

    const updateService = async () => {
        let bodyReq = {
            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,

            price: formData.price,
            video: formData.video,
            online: false
        }

        const flyer = await updateFlyer();

        if (flyer === 'error') {
            return
        }

        if (flyer !== false) {
            bodyReq.flyer = flyer;
        }

        let images = [];
        let stop = false;

        for (let i = 0; i < formData.images.length; i++) {
            if (stop) {
                return
            }

            const image = await updateImages(i);

            if (image === 'error') {
                return
            }
            if (image) {
                images.push(image);
            }
        }

        bodyReq.images = images;

        const req = await fetch(`https://api.pro-vide.eu/admin/services/${serviceSelected._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(bodyReq)
        });
        const res = await req.json();

        if (res && res.length !== 0) {
            alert('Le service a bien été mis à jour');
            dispatch(addServicesToState(res));
            return
        }
        alert('Les fichiers et images on bien était traité mais le service n\'a pas était mis à jour')
    }

    return (
        <div className='form_container form_updateService'>
            <i className="slash"></i>

            {categories && categories.length !== 0 &&
                <SelectorService allCategories={categories} allServices={services} setServiceSelected={setServiceSelected} />
            }

            <i className="slash"></i>

            <h2>Modifier un service</h2>

            {serviceSelected &&
                <section className="sect_nameAndText_multiLang">
                    <NameAndText lang='fr' holder='de la catégorie' nameValue={formData.name_fr} textValue={formData.text_fr} setFormData={setFormData} />
                    <NameAndText lang='en' holder='de la catégorie' nameValue={formData.name_en} textValue={formData.text_en} setFormData={setFormData} />
                    <NameAndText lang='sp' holder='de la catégorie' nameValue={formData.name_sp} textValue={formData.text_sp} setFormData={setFormData} />
                    <NameAndText lang='de' holder='de la catégorie' nameValue={formData.name_de} textValue={formData.text_de} setFormData={setFormData} />
                </section>
            }

            {serviceSelected &&
                <div className="container_fields">
                    <Input label='Prix' placeholder='199.99' value={formData.price} setValue={setFormData} field='price' />
                    <Input label='Video' placeholder='http://link-video.fr' value={formData.video} setValue={setFormData} field='video' />

                    <div className='input_file'>
                        <label>Flyer</label>

                        <div className="input">
                            <p className="filename">{filename || '+ Ajouter un pdf'}</p>

                            <input type="file" onChange={(e) => handleChangeFile(e)} />

                            {lastData && filename !== lastData.flyer &&
                                //Button delete flyer
                                <img
                                    className="btn_reload"
                                    src={icon_reload} alt="_"
                                    onClick={() => setReload(p => p + 1)}
                                />
                            }

                            {filename &&
                                //Button delete flyer
                                <img
                                    className="btn_delete"
                                    src={icon_delete} alt="_"
                                    onClick={() => {
                                        setFilename('');
                                        setFormData({ ...formData, flyer: '' });
                                    }}
                                />
                            }
                        </div>
                    </div>

                </div>
            }

            {serviceSelected && formData && formData.images.length !== 0 &&
                <MultiInputImage lastImages={lastData?.images} dataImages={formData.images} reloading={reload} setFormData={setFormData} />
            }

            {serviceSelected && formData &&
                <button onClick={() => updateService()} className="submitForm black">Modifier le service</button>
            }
        </div>
    )
}

export default UpdateService;
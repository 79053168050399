import "./style.css";



function Input({ label, placeholder, value, setValue, field, type }) {

    const isPassword = field === 'password' || field === 'passw' || field === '_passw';

    // Mettre à jour la valeur
    const handleChange = (e) => {
        const value = e.target.value;

        setValue(prev => ({
            ...prev,
            [field]: value
        }));
    };

    // <Input label={} placeholder={} value={} setValue={} field={} />

    // Rendu
    return (
        <div className='fields'>
            <label>{label}</label>
            <input
                type={type ? type : isPassword ? 'password' : 'text'}

                className={`input_${field}`}
                placeholder={placeholder}

                onChange={handleChange}
                value={value}
            />
        </div>
    );
}

export default Input;
import { useEffect, useState } from "react";
import "./style.css";

function SelectorParent({ data, setParentId, setListToDelete, type, setType, lock }) {
    const [selectValue, setSelectValue] = useState('null');
    const [categories, setCategories] = useState([]);
    const [path, setPath] = useState([]);

    // Initialisation
    useEffect(() => {
        reloadSeletor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        reloadSeletor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, type])

    useEffect(() => {
        if (setListToDelete) {
            setListToDelete(categories);
        }
    }, [setListToDelete, categories])

    // Reload
    function reloadSeletor() {
        setCategories(data.filter(d => !d.parentId));
        setParentId(prev => {
            return { ...prev, parentId: null }
        });

        setSelectValue('null');
        setPath([]);
    }

    // Retour en arrière
    const setHistorical = (parent) => {
        setParentId(prev => {
            return { ...prev, parentId: parent._id }
        });

        setCategories(data.filter(d => (d.type === type || type === 'all') && d.parentId === parent._id));
        setSelectValue('null');

        let newPath = [];
        let pathIsUpdate = false;

        for (let element of path) {
            if (pathIsUpdate) { continue }

            if (element._id === parent._id) {
                pathIsUpdate = true;
            }

            newPath.push(element);
        }

        setPath(newPath);
    }

    // Selection
    const selectParent = (value) => {
        if (value === 'null') {
            return
        }

        const catSelected = data.find(d => d._id === value);

        setParentId(prev => {
            return { ...prev, parentId: value }
        });

        if (!lock) {
            setPath([
                ...path,
                {
                    _id: value,
                    name: catSelected[`name_fr`]
                }
            ]);

            setCategories(data.filter(d => (d.type === type || type === 'all') && d.parentId === value));
            setSelectValue('null');
        }
        else {
            setPath([
                {
                    _id: value,
                    name: catSelected[`name_fr`]
                }
            ]);
            setSelectValue(value);
        }
    }

    return (
        <div className='selector'>
            <div className="historical">
                <span>Parent :</span>
                <p className="parent_selected">{path && path.length !== 0 ? path[path.length - 1].name : 'Aucun'}</p>
            </div>

            {setType &&
                <div className="historical">
                    <span>Type de catégorie :</span>
                    <select onChange={(e) => setType(e.target.value)}>
                        <option value='prod'>produit</option>
                        <option value='serv'>service</option>
                    </select>
                </div>
            }

            <div className="historical">
                <span>Localisation :</span>

                <button onClick={() => reloadSeletor()}>Catégories <i>{'>'}</i></button>

                {path && path.length !== 0 && path.map((e, idx) => idx !== path.length - 1 &&
                    <button
                        key={idx}
                        onClick={() => setHistorical(e)}
                    >{e.name} <i>{'>'}</i></button>
                )}
            </div>

            {categories && categories.length !== 0 &&
                <select
                    value={selectValue}
                    onChange={(e) => selectParent(e.target.value)}
                >
                    <option value='null'>Choisissez une catégorie</option>

                    {type === 'all' && categories.map((cat, idx) => cat.type === 'prod' &&
                        <option
                            key={idx}
                            value={cat._id}
                        >{cat[`name_fr`]}</option>
                    )}

                    {type === 'all' && categories.map((cat, idx) => cat.type === 'serv' &&
                        <option
                            key={idx}
                            value={cat._id}
                        >{cat[`name_fr`]}</option>
                    )}

                    {type === 'prod' && categories.map((cat, idx) => cat.type === type &&
                        <option
                            key={idx}
                            value={cat._id}
                        >{cat[`name_fr`]}</option>
                    )}

                    {type === 'serv' && categories.map((cat, idx) => cat.type === type &&
                        <option
                            key={idx}
                            value={cat._id}
                        >{cat[`name_fr`]}</option>
                    )}
                </select>
            }
        </div>
    );
}

export default SelectorParent;
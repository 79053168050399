import btn_delete from './../../assets/svg/btn_close.svg'

import "./style.css";

function ListToDelete({ data, setToDelete, type }) {


    return (
        <ul className='listToDelete'>
            {data.map((element, idx) => !type ?
                <li key={idx}>
                    <h3>{element.name_fr}</h3>
                    <img onClick={() => setToDelete(element._id)} src={btn_delete} alt="_" />
                </li> 
                : element.type === type &&
                <li key={idx}>
                    <h3>{element.name_fr}</h3>
                    <img onClick={() => setToDelete(element._id)} src={btn_delete} alt="_" />
                </li>
            )}
        </ul>
    );
}

export default ListToDelete;
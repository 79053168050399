import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Input from '../../components/Input';

import backgroundImg from './../../assets/img/background.webp';
import logo_provide from './../../assets/img/logo-pro-vide.png';

import "./style.css"

function Login() {
    const navigate = useNavigate();

    const [msgError, setMsgError] = useState('');
    const [formData, setFormData] = useState({
        admin_id: '',
        admin_pw: ''
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        testConnection();
    }

    async function testConnection() {
        const req = await fetch(`https://api.pro-vide.eu/admin/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                admin_id: formData.admin_id,
                admin_pw: formData.admin_pw
            })
        });
        const res = await req.json(); 
        
        if (!res.token) {
            setMsgError('Information incorrecte');
            setTimeout(() => {
                setMsgError('');
            }, 3000)
            return
        }

        sessionStorage.setItem('admin_id', formData.admin_id);
        sessionStorage.setItem('admin_pw', formData.admin_pw);
        sessionStorage.setItem('token', res.token);
        navigate('/');

        window.location.reload();
    }

    return (
        <div className='page_login'>
            <img className="background_img" src={backgroundImg} alt="_" />

            <section className='sect_formLogin'>
                <div className="logo_title">
                    <img src={logo_provide} alt="_" />
                    <h1>Pro-vide<span>Créateur de solution</span></h1>
                </div>

                <form onSubmit={handleSubmit}>
                    <h2>Connection</h2>

                    <div className="container_fields">
                        <Input label='Identifiant' placeholder='Garfields' value={formData.admin_id} setValue={setFormData} field='admin_id' />
                        <Input label='Mot de passe' placeholder='************' value={formData.admin_pw} setValue={setFormData} field='admin_pw' />
                    </div>

                    <p className="msgError">{msgError && msgError !== '' && '- ' + msgError}</p>

                    <button type='submit'>Connection</button>
                </form>
            </section>
        </div>
    )
}

export default Login;
import { useEffect, useState } from 'react';

import icon_picture from './../../assets/svg/icon_picture.svg';
import btn_reload from './../../assets/svg/button_reload.svg';
import btn_delete from './../../assets/svg/btn_close.svg';

import "./style.css";

function MultiInputImage({ lastImages, dataImages, reloading, setFormData }) {
    const [preview, setPreview] = useState(Array(9).fill(null));

    const [imageSelected, setImageSelected] = useState(null);
    const [imageNumber, setImgNumber] = useState(0);
    const [inpValue, setInpValue] = useState('');
    const [reload, setReload] = useState(0);

    // Si il y a des images pré-existantes
    useEffect(() => {
        // Recuperation d'image
        const fetchImg = async (imgId) => {
            const req = await fetch(`https://api.pro-vide.eu/public/image/${imgId}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });
            const res = await req.json();
            return res && res.image ? res.image : null;
        };

        // Recheche d'image pré-existante
        const updatePreview = async () => {
            if (!lastImages || lastImages.length === 0) {
                setPreview(Array(9).fill(null));
                setImageSelected(null);
                setImgNumber(0);
                setInpValue('');
                return;
            }

            const fetchedImages = await Promise.all(
                lastImages.slice(0, 9).map(imgId => fetchImg(imgId))
            );

            const newPreview = [...fetchedImages, ...Array(9 - fetchedImages.length).fill(null)];

            setFormData(p => {
                return {
                    ...p,
                    images: newPreview
                }
            })
            setPreview(newPreview);
        };

        setFormData(prev => ({
            ...prev,
            images: Array(9).fill(null)
        }));

        updatePreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastImages, reload]);

    useEffect(() => {
        setPreview(Array(9).fill(null));
        setImageSelected(null)
        setInpValue('');
    }, [reloading])

    // Mettre à jour la valeur & afficher la preview
    const handleChange = (e) => {
        const file = e.target.files[0];
        setInpValue(file);

        let newImages = [...dataImages];
        let newPreview = [...preview];

        if (!file) {
            let newPreview = [...preview];
            newPreview[imageNumber] = null;

            setInpValue('');
            setPreview(newPreview);
            setFormData(prev => ({
                ...prev,
                images: newPreview
            }));
            return
        }

        // Créer une URL temporaire pour afficher la prévisualisation
        const previewUrl = URL.createObjectURL(file);
        setImageSelected(previewUrl);

        // Ajout de la preview au tableau
        newPreview[imageNumber] = previewUrl;
        setPreview(newPreview);

        // Ajoute l'image au formData
        newImages[imageNumber] = file;
        setFormData(prev => ({
            ...prev,
            images: newImages
        }));

        // Reload input file
        setInpValue('');
    };

    // Selection du'une autre image
    const handleImageSelected = (idx) => {
        setImageSelected(preview[idx]);
        setImgNumber(idx);
    }

    // Supprime l'image selectetionné
    const deleteImageSelected = (idx) => {
        let newImages = [...dataImages];
        let newPreview = [...preview];

        // Supprime la preview du tableau
        newPreview[idx] = null;
        setPreview(newPreview);

        // Supprime l'image du formData
        newImages[idx] = null;
        setFormData(prev => ({
            ...prev,
            images: newImages
        }));
    }

    // Rendu
    return (
        <div className='multi_input_img'>
            {lastImages && lastImages.length !== 0 &&
                <img
                    className='btn_reload'
                    src={btn_reload} alt=""
                    onClick={() => setReload(prev => prev + 1)}
                />
            }
            <div className='input_img'>
                {imageSelected === null && <img className='icon' src={icon_picture} alt="_" />}
                {imageSelected === null && <label>Ajouter/Modifier image</label>}
                {imageSelected !== null && <img className='previewSelected' src={imageSelected} alt='Non supporté' />}
                <input
                    type="file"
                    value={inpValue}
                    onChange={(e) => handleChange(e)}
                />
            </div>

            {preview && preview.length !== 0 &&
                <div className="container_images">
                    {preview.map((img, idx) =>
                        <button
                            key={idx}
                            className={idx === imageNumber ? 'actif' : ''}
                            onClick={() => handleImageSelected(idx)}
                        >
                            {img === null && <img className='icon' src={icon_picture} alt="_" />}
                            {img === null && <label>Image n°{idx + 1}</label>}

                            {img !== null && <img className='preview' src={img} alt="Non supporté" />}
                            {img !== null && <img onClick={() => deleteImageSelected(idx)} className='btn_delete' src={btn_delete} alt="_" />}
                        </button>
                    )}
                </div>
            }
        </div>
    );
}

export default MultiInputImage;
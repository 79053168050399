import { useState } from "react";

import CreateCategorie from './../../forms/categories/CreateCategorie';
import UpdateCategorie from './../../forms/categories/UpdateCategorie';
import DeleteCategorie from './../../forms/categories/DeleteCategorie';

import SelectorTypeForm from "../../components/SelectorTypeForm";

import "./style.css"

function Categories() {
    const [typeForm, setTypeForm] = useState('add');
    
    return (
        <div className='page page_categories'>
            <SelectorTypeForm type={typeForm} setType={setTypeForm} />

            {typeForm && typeForm === 'add' && <CreateCategorie />}
            {typeForm && typeForm === 'edit' && <UpdateCategorie />}
            {typeForm && typeForm === 'delete' && <DeleteCategorie />}
        </div>
    )
}

export default Categories;
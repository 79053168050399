import { useSelector } from "react-redux";
import "./style.css"

const title = {
    fr: `Description`,
    en: `Description`,
    sp: `Descripción`,
    de: `Beschreibung`
};

function Description({ data }) {    
    const lang = useSelector(state => state.lang);

    return (
        <div className='modal modal_description'>
            <h4>{title[lang]}</h4>
            <p>{data}</p>
        </div>
    )
}

export default Description;
import { useSelector } from "react-redux"
import "./style.css"

const title = {
    fr: `Plans à disposition`,
    en: `Available Plans`,
    sp: `Planes disponibles`,
    de: `Verfügbare Pläne`
}
const msg = {
    fr: `Aucun lien`,
    en: `No link`,
    sp: `Ningún enlace`,
    de: `Kein Link`
}

function Download({ data }) {
    const lang = useSelector(state => state.lang);

    const downloadFile = async (url) => {
        const a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', '');
        a.setAttribute('target', '_blank') // Force le téléchargement sans spécifier le nom de fichier, le nom original sera utilisé
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    return (
        <div className='modal modal_download'>
            <h4>{title[lang]}</h4>

            {data && data.length !== 0 ? (
                <div className="container">
                    {data.map((link, idx) => link !== '' && (
                        <button
                            key={idx}
                            className="btn-download"
                            onClick={() => downloadFile(link.url)}
                        >
                            - <span>{link.filename}</span>
                        </button>
                    ))}
                </div>
            ) :
                <p>{msg[lang]}</p>
            }
        </div>
    )
}

export default Download;
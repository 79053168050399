import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import SelectorProductsAssociated from "../SelectorProductsAssociated";
import ShowProdAssociated from "../ShowProdAssociated";

import "./style.css";

function ProdAssociated({ lastData, formData, setFormData }) {
    const products = useSelector(state => state.data.products);
    const categories = useSelector(state => state.data.categories);

    const [prodList, setProdList] = useState([]);
    const [list, setList] = useState([]);

    // lastData loading
    useEffect(() => {
        if (lastData) {
            setList(lastData.associated);
        }
    }, [lastData])

    // get products associated
    useEffect(() => {
        if (!list) {
            return
        }

        setFormData({ ...formData, associated: list });
        
        if (list.length === 0) {
            setProdList(list);
            return
        }

        const fetchImgProd = async (imgId) => {
            const req = await fetch(`https://api.pro-vide.eu/public/image/${imgId}`);
            const res = await req.json();

            if (res && res.image) {
                return res.image
            }
            return null
        }

        const fetchData = async () => {
            let newProdList = [];

            for (let i = 0; i < list.length; i++) {
                if (prodList[i] && prodList[i].name) {
                    newProdList.push(prodList[i]);
                    continue
                }
                const prodData = products.find(d => d._id === list[i]);

                let image = '';

                if (prodData && prodData.images.length !== 0) {
                    const prodImg = await fetchImgProd(prodData.images[0]);

                    if (prodImg !== null) {
                        image = prodImg;
                    }
                }

                newProdList.push({ ...prodData, images: image })
            }

            setProdList(newProdList);
        }

        fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list])

    return (
        <div className='sect_productsAssociated'>
            <h3>Produits associés</h3>

            {list && <SelectorProductsAssociated allCategories={categories} allProducts={products} list={list} setList={setList} />}

            <i className="slash"></i>

            {prodList && prodList.length !== 0} {
                <ShowProdAssociated data={prodList} setData={setProdList} list={list} setList={setList} />
            }
        </div>
    );
}

export default ProdAssociated;
import { useEffect, useState } from 'react';
import icon_delete from './../../assets/svg/btn_close.svg';

import "./style.css";

function DownloadPlans({ lastData, formData, setFormData }) {
    const [files, setFiles] = useState(Array(6).fill(null));

    useEffect(() => {
        if (!lastData) {
            return
        }

        if (lastData.files.length === 0) {
            setFiles(Array(6).fill(null));
            return
        }

        const fetchFiles = async () => {
            let list = [];

            for (let id of lastData.files) {
                const req = await fetch(`https://api.pro-vide.eu/public/plan/${id}`);
                const res = await req.json();

                if (res && res._id) {
                    list.push({ name: res.plan.split('/').pop() })
                }
                else {
                    list.push(null);
                }
            }
            setFiles(list);
        }
        fetchFiles();
        
    }, [lastData])

    useEffect(() => {
        if (files) {
            setFormData({ ...formData, files: files });
        }
    }, [files])

    const handleChange = (e, idx) => {
        let newArray = [...files];
        newArray[idx] = e.target.files[0];
        setFiles(newArray);
    }

    const deleteValue = (idx) => {
        let newArray = [...files];

        if (newArray[idx] !== null) {
            newArray[idx] = null;
        }
        else {
            newArray.splice(idx, 1);
        }
        setFiles(newArray);
    }

    const addInputFile = () => {
        let newArray = [...files, null];
        setFiles(newArray);
    }

    return files && (
        <div className='sect_downloadPlans'>
            <h3>Plans à télécharger</h3>

            <div className="container">
                {files.map((file, idx) =>
                    <div key={idx} className="inputFile">
                        <span>{file ? file.name : '+ Ajouter un plan'}</span>
                        <input type="file" onChange={(e) => handleChange(e, idx)} />
                        <img onClick={() => deleteValue(idx)} src={icon_delete} alt="_" />
                    </div>
                )}
                <button onClick={() => addInputFile()} className="add_inputFile">+ Ajouter un input</button>
            </div>
        </div>
    );
}

export default DownloadPlans;
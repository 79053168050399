import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import icon_requestPrice from "./../../assets/svg/request-price_icon-products.svg";
import icon_addToBasket from "./../../assets/svg/add-to-basket_icon-products.svg";

import "./style.css";

const button_schema = {
    fr: `Schema`,
    en: `Diagram`,
    sp: `Esquema`,
    de: `Schema`
}

function VerifReferences({ data, schema, setSchema }) {
    const lang = useSelector((state) => state.lang);

    // Form & array
    const [inputQte, setInputQte] = useState([]);
    const [dataTable, setDataTable] = useState({
        tableHead: [],
        tableData: [],
        stock: [],
        price: [],
    });

    // Filtrage des data
    useEffect(() => {
        let newDataTable = {
            tableHead: [],
            tableData: [],
            stock: [],
            price: [],
        };

        let arrayInput = [];

        // Reload
        if (!data.table_ref || data.table_ref.length === 0) {
            setDataTable(newDataTable);
            return;
        }

        const table_ref = data.table_ref;

        // Filtrage table-head
        for (let i = 0; i < table_ref[0].length; i++) {
            newDataTable.tableHead.push(table_ref[0][i]);

            if (i === table_ref[0].length - 1) {
                newDataTable.tableHead.push("Price");
                newDataTable.tableHead.push("Quantity");
            }
        }

        // Filtrage table-data
        for (let i = 1; i < table_ref.length; i++) {
            let row = [];
            arrayInput.push(1);

            for (let n = 0; n < table_ref[0].length; n++) {
                row.push(table_ref[i][n]);

                // Stock
                if (n === table_ref[0].length - 1) {
                    newDataTable.price.push(table_ref[i][n + 1] ? table_ref[i][n + 1] : '');
                }

                // Price
                if (n === table_ref[0].length - 1) {
                    newDataTable.stock.push(table_ref[i][n + 2] ? table_ref[i][n + 2] : '');
                }
            }
            newDataTable.tableData.push(row);
        }

        // Maj state
        setDataTable(newDataTable);
        setInputQte(arrayInput);
    }, [data]);

    // Voir schema n°ID
    async function showSchema(id) {
        if (id === schema.id) {
            return setSchema({
                id: "",
                url: "",
                small: "",
            });
        }

        const req = await fetch(`https://api.pro-vide.eu/public/image/${id}`);
        const res = await req.json();

        setSchema({
            id: id,
            url: res.image || "",
            small: res.small_image || "",
        });
    }

    // Formatage prix '31.00000' => '31.00'
    function formatPrice(num) {
        num = parseFloat(num);

        if (Math.floor(num) === num) {
            return num.toString() + ".00";
        }
        else {
            let fixedNum = num.toFixed(2);
            return fixedNum.split(".")[1] === "00" ? fixedNum.split(".")[0] : fixedNum;
        }
    }

    return dataTable &&
        <div className="modal modal_references">
            <div className="array">
                <div className="data">
                    {/* TH */}
                    <div className="tableHead">
                        {dataTable.tableHead.length !== 0 &&
                            dataTable.tableHead.map((th, idx) => (
                                <p key={idx} className={`th_cell${idx === 1 ? " th_schema" : ""}`}>
                                    {th}
                                </p>
                            ))}
                    </div>

                    {/* TR */}
                    {dataTable.tableData.map((row, idx) => (
                        <div key={idx} className="tableRow">
                            {/* Réferences */}
                            <p className="tr_cell">
                                {row[0]}
                                {(!dataTable.stock[idx] || dataTable.stock[idx] === '' || dataTable.stock[idx] < -10000) ?
                                    <span className="unavailable">out stock</span> :
                                    <span className="in_stock">stock</span>
                                }
                            </p>

                            {/* Schema */}
                            {row[1] !== '' && <button className="btn-schema" onClick={() => showSchema(row[1])}>Download</button>}
                            {row[1] === '' && <p className="btn-schema"></p>}

                            {row.map((v, id) => id > 1 && <p key={id} className="th_cell">{v}</p>)}

                            {/* Price */}
                            <p className="tr_cell">{dataTable.price[idx] !== '' && formatPrice(dataTable.price[idx]) + '€'}</p>

                            {/* Input quantity */}
                            <input
                                type="number"
                                value={inputQte[idx]}
                                className="input_qte"
                                readOnly
                            />
                        </div>
                    ))}
                </div>

                <div className="container-btnBuy">
                    <p className="th_cell">{/* TH VIDE */}</p>

                    {dataTable.tableData.map((ref, idx) => dataTable.price[idx] === '' ?
                        /* Button request_price */
                        <button
                            key={idx}
                            className="btn-buy"
                        >
                            <img src={icon_requestPrice} alt="Request price" />
                            P.O.V
                        </button> :

                        /* Button add_to_basket */
                        <button
                            key={idx}
                            className="btn-buy"
                        >
                            <img src={icon_addToBasket} alt="Add to basket" />
                        </button>
                    )}
                </div>
            </div>

            {/* Description du tableau */}
            <p className="descrip_tableRef">{data?.ref_descrip}</p>
        </div>
}

export default VerifReferences;
import { useEffect, useState } from "react";
import "./style.css";

function SelectorRealization({ allCategories, allRealizations, setRealizationSelected, setListToDelete }) {
    const [selectRealValue, setSelectRealValue] = useState('null');
    const [selectCatValue, setSelectCatValue] = useState('null');

    const [realizations, setRealizations] = useState([]);
    const [categories, setCategories] = useState([]);

    const [realSelected, setRealSelected] = useState({ _id: '', name: '' });
    const [path, setPath] = useState([]);

    // Initialisation
    useEffect(() => {
        reloadSeletor();
    }, [])

    // Reload
    function reloadSeletor() {
        setCategories(allCategories.filter(d => !d.parentId));
        setRealizations([]);
        
        setSelectRealValue('null');
        setSelectCatValue('null');
        
        setRealSelected({ _id: '', name: '' });
        setRealizationSelected(null);
        setPath([]);
    }

    // Retour en arrière
    const setHistorical = (parent) => {
        setRealizationSelected(null);

        setCategories(allCategories.filter(d => d.parentId === parent._id));
        setRealizations(allRealizations.filter(d => d.parentId === parent._id));

        setRealSelected({ _id: '', name: '' });
        setSelectRealValue('null');

        let newPath = [];
        let pathIsUpdate = false;

        for (let element of path) {
            if (pathIsUpdate) { continue }

            if (element._id === parent._id) {
                pathIsUpdate = true;
            }

            newPath.push(element);
        }

        setPath(newPath);
    }

    // Selection de catégorie
    const selectCategory = (value) => {
        if (value === 'null') {
            return
        }

        const catSelected = allCategories.find(d => d._id === value);
        // setRealizationSelected(value);

        setPath([
            ...path,
            {
                _id: value,
                name: catSelected[`name_fr`]
            }
        ]);

        setCategories(allCategories.filter(d => d.parentId === value));
        setRealizations(allRealizations.filter(d => d.parentId === value));
        
        if (setListToDelete) {
            setListToDelete(allRealizations.filter(d => d.parentId === value));
        }
        
        setSelectRealValue('null');
        setSelectCatValue('null');
    }

    // Selection de service
    const selectRealization = (value) => {
        if (value === 'null') {
            return
        }

        setRealizations([]);
        const serv = allRealizations.find(d => d._id === value);
        setRealSelected({ _id: serv._id, name: serv[`name_fr`] });
        
        setRealizationSelected(serv);
    }

    return (
        <div className='selector'>
            <div className="historical">
                <span>Réalisation :</span>
                <p className="parent_selected">{realSelected && realSelected.name ? realSelected.name : 'Aucun'}</p>
            </div>

            
            <div className="historical">
                <span>Localisation :</span>

                <button onClick={() => reloadSeletor()}>Catégories <i>{'>'}</i></button>

                {path && path.length !== 0 && path.map((e, idx) =>
                    <button
                        key={idx}
                        onClick={() => setHistorical(e)}
                    >{e.name} <i>{'>'}</i></button>
                )}
            </div>

            {categories && categories.length !== 0 &&
                <select
                    value={selectCatValue}
                    onChange={(e) => selectCategory(e.target.value)}
                >
                    <option value='null'>Choisissez une catégorie</option>
                    {categories.map((cat, idx) =>
                        <option
                            key={idx}
                            value={cat._id}
                        >{cat[`name_fr`]}</option>
                    )}
                </select>
            }

            {!setListToDelete && realizations && realizations.length !== 0 &&
                <select
                    value={selectRealValue}
                    onChange={(e) => selectRealization(e.target.value)}
                >
                    <option value='null'>Selectionnez une réalisation</option>
                    {realizations.map((serv, idx) =>
                        <option
                            key={idx}
                            value={serv._id}
                        >{serv[`name_fr`]}</option>
                    )}
                </select>
            }
        </div>
    );
}

export default SelectorRealization;
/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import SelectorProduct from "../../../components/SelectorProduct";
import NameAndText from "../../../components/NameAndText";
import MultiInputImage from "../../../components/MultiInputImage";
import ListDataProducts from "../../../components/ListDataProducts";
import References from "../../../components/References";
import DownloadPlans from "../../../components/DownloadPlans";
import ProdAssociated from "../../../components/ProdAssociated";

import "./style.css"

function UpdateProduct() {
    const categories = useSelector(state => state.data.categories);
    const products = useSelector(state => state.data.products);
    const user = useSelector(state => state.user);

    const [productSelected, setProductSelected] = useState(null);

    const [lastData, setLastData] = useState(null);
    const [formData, setFormData] = useState({
        parentId: '',

        name_fr: '',
        name_en: '',
        name_sp: '',
        name_de: '',

        text_fr: '',
        text_en: '',
        text_sp: '',
        text_de: '',

        benefits_fr: [],
        benefits_en: [],
        benefits_sp: [],
        benefits_de: [],

        features_fr: [],
        features_en: [],
        features_sp: [],
        features_de: [],

        ref_descrip_fr: '',
        ref_descrip_en: '',
        ref_descrip_sp: '',
        ref_descrip_de: '',
        table_ref: [],

        associated: [],
        images: Array(9).fill(null),
        files: []
    });

    // Recup des données de la catégorie selectionné
    useEffect(() => {
        if (!productSelected) {
            setLastData(null);
            setFormData({
                parentId: '',

                name_fr: '',
                name_en: '',
                name_sp: '',
                name_de: '',

                text_fr: '',
                text_en: '',
                text_sp: '',
                text_de: '',

                benefits_fr: [],
                benefits_en: [],
                benefits_sp: [],
                benefits_de: [],

                features_fr: [],
                features_en: [],
                features_sp: [],
                features_de: [],

                ref_descrip_fr: '',
                ref_descrip_en: '',
                ref_descrip_sp: '',
                ref_descrip_de: '',
                table_ref: [],

                associated: [],
                images: Array(9).fill(null),
                files: []
            });

            return
        }

        setFormData({ ...productSelected, images: Array(9).fill(null) });
        setLastData(productSelected);
    }, [productSelected])

    const postFiles = async (type, file) => {
        const body = new FormData();

        if (type === 'img') {
            body.append('image', file);
        }
        else {
            body.append('plan', file);
        }

        const req = await fetch(`https://api.pro-vide.eu/admin/${type}`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${user.token}` },
            body: body
        });
        const res = await req.json();

        if (res) {
            return res
        }
        return false
    }

    const deleteFiles = async (type, id) => {
        const req = await fetch(`https://api.pro-vide.eu/admin/${type}/${id}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${user.token}` }
        });
        const res = await req.json();

        return res ? res : false;
    }

    const updatePlans = async () => {
        const formFiles = formData.files;
        const lastFiles = lastData.files;

        let plans = [];

        // No plans
        if (formFiles.length === 0 && lastFiles.length === 0) {
            return plans
        }

        for (let i = 0; i < 9; i++) {

            // Delete all plans
            if (formFiles.length === 0 && lastFiles.length !== 0 && lastFiles[i]) {
                    const deleteFile = await deleteFiles('plan', lastFiles[i]);

                    if (!deleteFile) {
                        alert(`Erreur impossible de supprimer un plan _id: ${lastFiles[i]}`);
                        plans.push(lastFiles[i]);
                        continue
                    }
                continue
            }

            // No change
            if (formData[i] && formFiles[i].name && !formFiles[i].size && lastFiles[i]) {
                plans.push(lastFiles[i]);
                continue
            }

            // Add/Update plans
            if (formFiles[i] && formFiles[i].size) {
                // Delete last
                if (lastFiles[i]) {
                    const delLastFile = await deleteFiles('plan', lastFiles[i]);

                    if (!delLastFile) {
                        alert(`Erreur impossible de supprimer un plan _id: ${lastFiles[i]}`);
                        plans.push(lastFiles[i]);
                        continue
                    }
                }

                const planId = await postFiles('plan', formFiles[i]);

                if (planId) {
                    plans.push(planId);
                    continue
                }
                alert(`Erreur impossible de télécharger le plan n°${i + 1}`);
                continue
            }

            // Delete last
            if (formFiles[i] && formFiles[i] === '' && lastFiles[i]) {
                const delLastFile = await deleteFiles('plan', lastFiles[i]);

                if (!delLastFile) {
                    alert(`Erreur impossible de supprimer un plan _id: ${lastFiles[i]}`);
                    plans.push(lastFiles[i]);
                    continue
                }
            }

            // Delete last
            if (!formFiles[i] && lastFiles[i]) {
                const delLastFile = await deleteFiles('plan', lastFiles[i]);

                if (!delLastFile) {
                    alert(`Erreur impossible de supprimer un plan _id: ${lastFiles[i]}`);
                    plans.push(lastFiles[i]);
                    continue
                }
            }
        }

        return plans
    }

    const updateImages = async () => {
        let images = [];

        for (let i = 0; i < formData.images.length; i++) {
            const newImage = formData.images[i];
            const lastImage = lastData.images[i];

            // No change
            if (newImage === null && !lastImage) {
                continue
            }

            // Add last data
            if (newImage !== null && !newImage.name && lastImage) {
                images.push(lastImage);
            }

            // Add / Update
            if (newImage && newImage.name && newImage.size) {
                if (lastImage) {
                    const imageDelete = await deleteFiles('img', lastImage);

                    if (!imageDelete) {
                        alert(`Impossible de supprimer l'ancienne image n°${i + 1}`);
                        images.push(lastImage)
                        continue
                    }
                }

                const imgId = await postFiles('img', newImage);

                if (imgId) {
                    images.push(imgId);
                    continue
                }
                alert(`Impossible d'ajouter l'image n°${i + 1}`);
                continue
            }

            // Delete
            if (newImage === null && lastImage) {
                const imageDelete = await deleteFiles('img', lastImage);

                if (!imageDelete) {
                    alert(`Impossible de supprimer l'ancienne image n°${i + 1}`);
                    images.push(lastImage);
                }
            }
        }

        return images
    }

    const updateTableRef = async () => {
        // Si les deux tableaux sont vides, retourner un tableau vide
        if (lastData.table_ref.length === 0 && formData.table_ref.length === 0) {
            return [];
        }
    
        const formTable = formData.table_ref;
        const lastTable = lastData.table_ref;
    
        let table_ref = [[...formTable[0]]];
        const length = formTable.length >= lastTable.length ? formTable.length : lastTable.length;
    
        for (let i = 1; i < length; i++) {
            let row = formTable[i];
            let lastRow = lastTable[i];
    
            console.log(row);

            if (!row) {
                row = [];
            }
            if (!lastRow) {
                lastRow = [];
            }
    
            // Si il n'y a pas de fichier
            if (row[1] === null) {
                row[1] = '';
            }

            // Si il n'y a pas de schema
            if (row[1] === '' && lastRow[1] === '') {
                table_ref.push(row);
                continue;
            }
    
            // Si on change rien
            if (row[1] && !row[1].size && row[1].name && lastRow[1] !== '') {
                row[1] = lastRow[1];
                table_ref.push(row);
                continue;
            }
    
            // Si il y a un schema
            if (row[1] && row[1] !== '') {

                // Si il y a un ancien schema
                if (lastRow[1] !== '') {
                    const schemaDelete = await deleteFiles('img', lastRow[1]);
    
                    if (!schemaDelete) {
                        alert(`Impossible de supprimer l'ancien schema n°${i + 1}`);
                        row[1] = lastRow[1];
                        table_ref.push(row);
                        continue;
                    }
                }
    
                // Enregistrer le schema
                const schemaId = await postFiles('plan', row[1]);
    
                if (schemaId) {
                    row[1] = schemaId;
                    table_ref.push(row);
                    continue;
                }
                
                alert(`Impossible d'ajouter le plan 3d n°${i + 1}`);
                row[1] = lastRow[1];
                table_ref.push(row);
                continue;
            }
    
            // Si on supprime l'ancien schema
            if (
                (row[1] === '' && lastRow[1] !== '') || 
                (row.length === 0 && lastRow[1] !== '')
            ) {
                const schemaDelete = await deleteFiles('plan', lastRow[1]);
    
                if (!schemaDelete) {
                    alert(`Impossible de supprimer l'ancien schema n°${i + 1}`);
                    row[1] = lastRow[1];
                    table_ref.push(row);
                } 
                else if (row.length !== 0 && row[0] !== '') {
                    row[1] = '';
                    table_ref.push(row);
                }
            }
        }
    
        return table_ref;
    }

    const updateProduct = async () => {
        const dataCheck = checkData();
        const body = {
            parentId: formData.parentId,
            name_fr: formData.name_fr,
            online: false,
            ...dataCheck
        };

        const images = await updateImages();
        body.images = images;

        const files = await updatePlans();
        body.files = files;

        const table_ref = await updateTableRef();
        body.table_ref = table_ref;

        const req = await fetch(`https://api.pro-vide.eu/admin/products/${productSelected._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(body)
        });
        const res = await req.json();

        if (res && res.length !== 0) {
            alert('Le produit à bien été modifier');
            return
        }
        alert('Les fichiers et images on bien était traité mais le service n\'a pas était mis à jour. CONTACTEZ LE SUPPORT SANS ATTENDRE')
    }

    function checkData() {
        let body = {};

        const fields = [
            'name_en', 'name_sp', 'name_de', 'text_fr', 'text_en', 'text_sp', 'text_de', 'ref_descrip_fr', 'ref_descrip_en', 'ref_descrip_sp', 'ref_descrip_de'
        ];

        for (let field of fields) {
            if (formData[field] && formData[field] !== '') {
                body[field] = formData[field];
            }
        }

        const arrays = [
            'benefits_fr', 'benefits_en', 'benefits_sp', 'benefits_de', 'features_fr', 'features_en', 'features_sp', 'features_de', 'associated'
        ];

        for (let array of arrays) {
            if (formData[array] && formData[array].length !== 0) {
                let newArray = [];

                for (let data of formData[array]) {
                    if (data && data !== null && data !== '') {
                        newArray.push(data);
                    }
                }

                body[array] = newArray;
            }
        }

        return body;
    }

    return (
        <div className='form_container form_updateProduct'>
            <i className="slash"></i>

            {categories && categories.length !== 0 &&
                <SelectorProduct allCategories={categories} allProducts={products} setProductSelected={setProductSelected} />
            }

            <i className="slash"></i>

            <h2>Modifier un produit</h2>

            {productSelected && formData && lastData &&
                <>
                    <section className="sect_nameAndText_multiLang">
                        <NameAndText lang='fr' holder='du produit' nameValue={formData.name_fr} textValue={formData.text_fr} setFormData={setFormData} />
                        <NameAndText lang='en' holder='du produit' nameValue={formData.name_en} textValue={formData.text_en} setFormData={setFormData} />
                        <NameAndText lang='sp' holder='du produit' nameValue={formData.name_sp} textValue={formData.text_sp} setFormData={setFormData} />
                        <NameAndText lang='de' holder='du produit' nameValue={formData.name_de} textValue={formData.text_de} setFormData={setFormData} />
                    </section>

                    <MultiInputImage lastImages={lastData?.images} dataImages={formData.images} setFormData={setFormData} />

                    <i className="slash"></i>

                    <ListDataProducts lastData={lastData} formData={formData} setFormData={setFormData} />

                    <i className="slash"></i>

                    <References lastData={lastData} formData={formData} setFormData={setFormData} />

                    <i className="slash"></i>

                    <DownloadPlans lastData={lastData} formData={formData} setFormData={setFormData} />

                    <i className="slash"></i>

                    <ProdAssociated lastData={lastData} formData={formData} setFormData={setFormData} />

                    <button onClick={() => updateProduct()} className="submitForm black">Modifier le produit</button>
                </>
            }
        </div>
    )
}

export default UpdateProduct;
import Input from './../Input';
import Textarea from './../Textarea';

import flag_fr from './../../assets/svg/flag-fr.svg'
import flag_en from './../../assets/svg/flag-en.svg'
import flag_sp from './../../assets/svg/flag-es.svg'
import flag_de from './../../assets/svg/flag-de.svg'

import "./style.css"

function NameAndText({ lang, holder, nameValue, textValue, setFormData }) {    
    return (
        <div className='bloc_nameAndTexte'>
            {lang && lang === 'fr' && <img src={flag_fr} alt="Français" />}
            {lang && lang === 'en' && <img src={flag_en} alt="English" />}
            {lang && lang === 'sp' && <img src={flag_sp} alt="Spanish" />}
            {lang && lang === 'de' && <img src={flag_de} alt="Deutsch" />}
            <Input label={`Nom (${lang})`} placeholder={`Saisissez le nom ${holder}`} value={nameValue} setValue={setFormData} field={`name_${lang}`} />
            <Textarea label={`Texte (${lang})`} placeholder={`Saisissez le texte ${holder}`} value={textValue} setValue={setFormData} field={`text_${lang}`} />
        </div>
    )
}

export default NameAndText;
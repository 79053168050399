import { useEffect, useState } from "react";
import "./style.css";

function SelectorCategory({ allCategories, setId }) {
    const [selectValue, setSelectValue] = useState('null');
    const [categories, setCategories] = useState([]);
    const [path, setPath] = useState([]);

    // Initialisation
    useEffect(() => {
        reloadSeletor();
    }, [])

    // Reload
    function reloadSeletor() {
        setCategories(allCategories.filter(d => !d.parentId));
        setSelectValue('null');
        setPath([]);
        setId(null);
    }

    // Retour en arrière
    const setHistorical = (parent) => {
        setId(parent._id);

        setCategories(allCategories.filter(d => d.parentId === parent._id));
        setSelectValue('null');

        let newPath = [];
        let pathIsUpdate = false;

        for (let element of path) {
            if (pathIsUpdate) { continue }

            if (element._id === parent._id) {
                pathIsUpdate = true;
            }

            newPath.push(element);
        }

        setPath(newPath);
    }

    // Selection
    const selectCategory = (value) => {
        if (value === 'null') {
            return
        }

        const catSelected = allCategories.find(d => d._id === value);
        setId(value);

        setPath([
            ...path,
            {
                _id: value,
                name: catSelected[`name_fr`]
            }
        ]);

        setCategories(allCategories.filter(d => d.parentId === value));
        setSelectValue('null');
    }

    return (
        <div className='selector'>
            <div className="historical">
                <span>Catégorie :</span>
                <p className="parent_selected">{path && path.length !== 0 ? path[path.length - 1].name : 'Aucune'}</p>
            </div>

            <div className="historical">
                <span>Localisation :</span>

                <button onClick={() => reloadSeletor()}>Catégories <i>{'>'}</i></button>

                {path && path.length !== 0 && path.map((e, idx) => idx !== path.length - 1 &&
                    <button
                        key={idx}
                        onClick={() => setHistorical(e)}
                    >{e.name} <i>{'>'}</i></button>
                )}
            </div>

            {categories && categories.length !== 0 &&
                <select
                    value={selectValue}
                    onChange={(e) => selectCategory(e.target.value)}
                >
                    <option value='null'>Choisissez une catégorie</option>
                    {categories.map((cat, idx) =>
                        <option
                            key={idx}
                            value={cat._id}
                        >{cat[`name_fr`]}</option>
                    )}
                </select>
            }
        </div>
    );
}

export default SelectorCategory;
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import SliderServ from "../../components/SliderServ";

import icon_flyer from './../../assets/svg/flyer_icon-services.svg'
import icon_contact from './../../assets/svg/contact_icon-product.svg'
import icon_phone from './../../assets/svg/phone_icon-product.svg'
import icon_message from './../../assets/svg/message_icon-product.svg'

import "./style.css";

const button_request = {
    fr: `Faire une demande`,
    en: `To make a request`,
    sp: `Para hacer una petición`,
    de: `Eine Anfrage stellen`
};
const msg_contact = {
    fr: `Si vous désirez une offre, ou de plus amples informations sur ce produit contactez-nous :`,
    en: `If you would like an offer, or more information on this product, contact us :`,
    sp: `Si desea una oferta o más información sobre este producto, contáctenos :`,
    de: `Wenn Sie ein Angebot oder weitere Informationen zu diesem Produkt wünschen, kontaktieren Sie uns :`
};
const button_contact = {
    fr: `Nous écrire`,
    en: `Write us`,
    sp: `Escribenos`,
    de: `Schreiben Sie uns`
};

function VerifService({ data, setCounter }) {
    const lang = useSelector(state => state.lang);
    const user = useSelector(state => state.user);

    const [flyer, setFlyer] = useState('');

    useEffect(() => {
        if (!data.flyer || data.flyer === '') {
            return
        }

        const fetchFlyer = async (flyerId) => {
            const req = await fetch(`https://api.pro-vide.eu/pdf/${flyerId}`);
            const res = await req.json();
            setFlyer(res ? res : '')
        }
        fetchFlyer(data.flyer);

    }, [data]);

    // Gestion saut à la ligne
    const transformText = (text) => {
        return text && text !== '' ? text.split('\n').map((str, index) => (
            <span key={index}>
                {str}
                <br />
            </span>
        )) : text;
    };

    // Découpage filename
    const getFileName = (url) => {
        if (!url) return '';

        let filename = url.substring(url.lastIndexOf('/') + 1); // Supprime les caractères avant le dernier '/'
        filename = filename.replace(/(-[^-]+)(?=\.)/g, ''); // Supprime les caractères après le premier tiret avant l'extension
        return filename;
    };

    const downloadFlyer = async (url) => {
        const a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', '');
        a.setAttribute('target', '_blank') // Force le téléchargement sans spécifier le nom de fichier, le nom original sera utilisé
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const next = () => {
        window.scrollTo(0, 0);
        setCounter(c => {
            return {
                products: c.products,
                services: c.services + 1,
                realizations: c.realizations
            }
        })
    }

    const setOnline = async () => {
        const req = await fetch(`https://api.pro-vide.eu/admin/services/${data._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ online: true })
        });
        const res = await req.json();

        if (res && res.length !== 0) {
            next();
        }
    }

    return data && (
        <div className='verif_service'>
            <div className="container-slider-descrip">
                <div className="slider-flyer">
                    <SliderServ data={data} />

                    {flyer && flyer !== '' && (
                        <button onClick={() => downloadFlyer(flyer.pdf)} className="flyer">
                            <img src={icon_flyer} alt="Flyer" />
                            <p>{getFileName(flyer.pdf)}</p>
                        </button>
                    )}
                </div>

                <div className="descrip">
                    <h2>{data[`name_${lang}`]}</h2>
                    <p>{transformText(data[`text_${lang}`])}</p>
                    <button>{button_request[lang]}</button>
                </div>
            </div>
            
            <div className="contact">
                <p><img src={icon_contact} alt="Icon" />{msg_contact[lang]}</p>

                <div className="contact-btn">
                    <a href="tel:+330255996505" className="btn-phone"><img src={icon_phone} alt="Icon" /> +33 2 55 99 65 05</a>
                    <button className="btn-email"><img src={icon_message} alt="Icon" />{button_contact[lang]}</button>
                </div>
            </div>
            
            <div className="container_btn_validator">
                <button onClick={() => next()} className='button_next'>Suivant</button>
                <button onClick={() => setOnline()} className='button_valid'>Valider</button>
            </div>
        </div>
    );
}

export default VerifService;
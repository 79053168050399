import { useEffect, useState } from "react";

import "./style.css"

function SliderServ({ data }) {
    const [counter, setCounter] = useState(0);
    const [images, setImages] = useState([]);

    // Récupération des images du produit
    useEffect(() => {
        const fetchImg = async () => {
            if (data && data.images) {
                const reqImgProd = await Promise.all(data.images.map(async (imgId) => {
                    if (imgId.length !== 0) {
                        const req = await fetch(`https://api.pro-vide.eu/public/image/${imgId}`);
                        const res = await req.json();
                        return res ? res : '';
                    }
                    else {
                        return '';
                    }
                }));
                setImages(reqImgProd);
            }
        }
        fetchImg();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return images && images.length !== 0 && (
        <div className="slider-serv">
            <div className="view">
                <img src={images[counter].image} alt={`Illustration ${counter}`} />
            </div>

            <div className="list">
                {images.map((img, idx) => (
                    <div
                        key={idx}
                        onClick={() => setCounter(idx)}
                        className={`preview ${counter === idx && 'actif'}`}
                    >
                        <img src={img.image} alt={`Illustration ${idx}`} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SliderServ;
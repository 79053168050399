import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Header from './layouts/Header'

import Home from './pages/Home'
import Error from './pages/Error'

import Login from './pages/Login'
import Verification from './pages/Verification'

import Categories from './pages/Categories'
import Products from './pages/Products'
import Services from './pages/Services'
import Realization from './pages/Realization'

import './App.css'

function App() {
    return (
        <React.StrictMode>
            <Router>
                <Header />

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<Error />} />

                    <Route path="/login" element={<Login />} />
                    <Route path="/verif" element={<Verification />} />
                    
                    <Route path="/categories" element={<Categories />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/realization" element={<Realization />} />
                </Routes>
            </Router>
        </React.StrictMode>
    )
}

export default App;
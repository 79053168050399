import { useEffect, useState } from "react";
import "./style.css";

function SelectorService({ allCategories, allServices, setServiceSelected, setListToDelete }) {
    const [selectServValue, setSelectServValue] = useState('null');
    const [selectCatValue, setSelectCatValue] = useState('null');

    const [categories, setCategories] = useState([]);
    const [services, setServices] = useState([]);

    const [servSelected, setServSelected] = useState({ _id: '', name: '' });
    const [path, setPath] = useState([]);

    // Initialisation
    useEffect(() => {
        reloadSeletor();
    }, [])

    // Reload
    function reloadSeletor() {
        setCategories(allCategories.filter(d => !d.parentId && d.type === 'serv'));
        setServices([]);
        
        setSelectServValue('null');
        setSelectCatValue('null');
        
        setServSelected({ _id: '', name: '' });
        setServiceSelected(null);
        setPath([]);
    }

    // Retour en arrière
    const setHistorical = (parent) => {
        setServiceSelected(null);

        setCategories(allCategories.filter(d => d.parentId === parent._id));
        setServices(allServices.filter(d => d.parentId === parent._id));

        setServSelected({ _id: '', name: '' });
        setSelectServValue('null');

        let newPath = [];
        let pathIsUpdate = false;

        for (let element of path) {
            if (pathIsUpdate) { continue }

            if (element._id === parent._id) {
                pathIsUpdate = true;
            }

            newPath.push(element);
        }

        setPath(newPath);
    }

    // Selection de catégorie
    const selectCategory = (value) => {
        if (value === 'null') {
            return
        }

        const catSelected = allCategories.find(d => d._id === value);
        // setServiceSelected(value);

        setPath([
            ...path,
            {
                _id: value,
                name: catSelected[`name_fr`]
            }
        ]);

        setCategories(allCategories.filter(d => d.parentId === value));
        setServices(allServices.filter(d => d.parentId === value));
        
        if (setListToDelete) {
            setListToDelete(allServices.filter(d => d.parentId === value));
        }
        
        setSelectServValue('null');
        setSelectCatValue('null');
    }

    // Selection de service
    const selectService = (value) => {
        if (value === 'null') {
            return
        }

        setServices([]);
        const serv = allServices.find(d => d._id === value);
        setServSelected({ _id: serv._id, name: serv[`name_fr`] });
        
        setServiceSelected(serv);
    }

    return (
        <div className='selector'>
            <div className="historical">
                <span>Service :</span>
                <p className="parent_selected">{servSelected && servSelected.name ? servSelected.name : 'Aucun'}</p>
            </div>

            
            <div className="historical">
                <span>Localisation :</span>

                <button onClick={() => reloadSeletor()}>Catégories <i>{'>'}</i></button>

                {path && path.length !== 0 && path.map((e, idx) =>
                    <button
                        key={idx}
                        onClick={() => setHistorical(e)}
                    >{e.name} <i>{'>'}</i></button>
                )}
            </div>

            {categories && categories.length !== 0 &&
                <select
                    value={selectCatValue}
                    onChange={(e) => selectCategory(e.target.value)}
                >
                    <option value='null'>Choisissez une catégorie</option>
                    {categories.map((cat, idx) =>
                        <option
                            key={idx}
                            value={cat._id}
                        >{cat[`name_fr`]}</option>
                    )}
                </select>
            }

            {!setListToDelete && services && services.length !== 0 &&
                <select
                    value={selectServValue}
                    onChange={(e) => selectService(e.target.value)}
                >
                    <option value='null'>Selectionnez un service</option>
                    {services.map((serv, idx) =>
                        <option
                            key={idx}
                            value={serv._id}
                        >{serv[`name_fr`]}</option>
                    )}
                </select>
            }
        </div>
    );
}

export default SelectorService;
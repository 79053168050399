import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addRealizationToState } from "../../../redux";

import SelectorParent from "../../../components/SelectorParent";
import NameAndText from "../../../components/NameAndText";
import MultiInputImage from "../../../components/MultiInputImage";

import "./style.css"

function CreateRealization() {
    const dispatch = useDispatch();
    
    const user = useSelector(state => state.user);
    const categories = useSelector(state => state.data.categories)
    const realizations = useSelector(state => state.data.realization);
    
    const [reload, setReload] = useState(0);
    const [formData, setFormData] = useState({
        parentId: '',

        name_fr: '',
        name_en: '',
        name_sp: '',
        name_de: '',

        text_fr: '',
        text_en: '',
        text_sp: '',
        text_de: '',

        images: Array(9).fill(null)
    });

    const postImages = async () => {
        let array = [];

        for (let img of formData.images) {
            if (img === null) {
                continue
            }

            const body = new FormData();
            body.append('image', img);

            const req = await fetch(`https://api.pro-vide.eu/admin/img`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${user.token}` // Correction de l'en-tête Authorization
                },
                body: body
            });
            const res = await req.json();
            if (res) {
                array.push(res);
            }
        }
        return array
    }

    const addNewRealization = async () => {
        if (formData.name_fr === '' || formData.parentId === '') {
            return
        }

        let bodyReq = {
            parentId: formData.parentId,

            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,
            
            online: false
        }

        const arrayImages = await postImages();
        bodyReq.images = arrayImages;

        const req = await fetch(`https://api.pro-vide.eu/admin/realization`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(bodyReq)
        });
        const res = await req.json();

        if (res && res._id) {
            alert('La réalisation a bien été ajouté');
            dispatch(addRealizationToState([...realizations, res]));
            setFormData({
                parentId: formData.parentId,

                name_fr: '',
                name_en: '',
                name_sp: '',
                name_de: '',

                text_fr: '',
                text_en: '',
                text_sp: '',
                text_de: '',

                images: Array(9).fill(null)
            })
            setReload(p => p + 1);
            return
        }
        alert('La réalisation n\'a pas pu être ajouté');
    }

    return (
        <div className='form_container form_createRealization'>
            <i className="slash"></i>

            {categories && categories.length !== 0 &&
                <SelectorParent data={categories} setParentId={setFormData} type='all' lock={true} />
            }

            <i className="slash"></i>

            <h2>Ajouter une réalisation</h2>

            {formData && formData.parentId !== '' && formData.parentId !== null &&
                <section className="sect_nameAndText_multiLang">
                    <NameAndText lang='fr' holder='de la réalisation' nameValue={formData.name_fr} textValue={formData.text_fr} setFormData={setFormData} />
                    <NameAndText lang='en' holder='de la réalisation' nameValue={formData.name_en} textValue={formData.text_en} setFormData={setFormData} />
                    <NameAndText lang='sp' holder='de la réalisation' nameValue={formData.name_sp} textValue={formData.text_sp} setFormData={setFormData} />
                    <NameAndText lang='de' holder='de la réalisation' nameValue={formData.name_de} textValue={formData.text_de} setFormData={setFormData} />
                </section>
            }

            {formData && formData.parentId !== '' && formData.parentId !== null && formData.images.length !== 0 &&
                <MultiInputImage dataImages={formData.images} reloading={reload} setFormData={setFormData} />
            }

            {formData && formData.parentId !== '' && formData.parentId !== null &&
                <button onClick={() => addNewRealization()} className="submitForm blue">Ajouter la réalisation</button>
            }
        </div>
    )
}

export default CreateRealization;
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./style.css";

const close = {
    fr: `Fermer`,
    en: `Close`,
    sp: `Cerrar`,
    de: `Schließen`
}

function VerifRealization({ data, setCounter }) {
    const lang = useSelector(state => state.lang);
    const user = useSelector(state => state.user);

    const [images, setImages] = useState([]);
    const [count, setCount] = useState(0);

    // Recup des images de la page
    useEffect(() => {
        if (!data || !data.images || data.images.length === 0) {
            setImages([]);
            return
        }

        const fetchImages = async () => {
            try {
                const reqImgProd = await Promise.all(data.images.map(async (img) => {
                    const req = await fetch(`https://api.pro-vide.eu/public/image/${img}`);
                    const res = await req.json();
                    return res ? res : '';
                }));

                setImages(reqImgProd);
            }
            catch (error) {
                console.error("Error fetching images:", error);
            }
        };

        fetchImages();

    }, [data])

    const previousSlide = () => {
        setCount(count === 0 ? parseInt(images.length) - 1 : count - 1);
    }

    const nextSlide = () => {
        setCount(count === parseInt(images.length) - 1 ? 0 : count + 1);
    }

    // Gestion saut à la ligne
    const transformText = (text) => {
        return text && text !== '' ? text.split('\n').map((str, index) => (
            <span key={index}>
                {str}
                <br />
            </span>
        )) : text;
    };

    const next = () => {
        window.scrollTo(0, 0);
        setCounter(c => {
            return {
                products: c.products,
                services: c.services,
                realizations: c.realizations + 1
            }
        })
    }

    const setOnline = async () => {
        const req = await fetch(`https://api.pro-vide.eu/admin/realization/${data._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ online: true })
        });
        const res = await req.json();

        if (res && res.length !== 0) {
            next();
        }
    }

    return data && (
        <div className='verif_realization'>
            <div className="container">
                <div className='onglet_real'>
                    <div className="container-img">
                        {images.length !== 0 && <img src={images[0].image} alt="Illustration" />}
                    </div>

                    <p>{data[`name_${lang}`]}</p>
                </div>
                
                <div className="content">
                    <div className="container-img">
                        {images && images.length > 1 && <i onClick={() => previousSlide()} className="arrow arrow_left">{'<'}</i>}
                        {images && images.length !== 0 && <img src={images[count].image} alt="Illustration" />}
                        {images && images.length > 1 && <i onClick={() => nextSlide()} className="arrow arrow_right">{'>'}</i>}
                    </div>
                    <div className="descrip">
                        <h2>{data[`name_${lang}`]}</h2>
                        <p>{transformText(data[`text_${lang}`])}</p>
                        <button>{close[lang]}</button>
                    </div>
                </div>
            </div>

            <div className="container_btn_validator">
                <button onClick={() => next()} className='button_next'>Suivant</button>
                <button onClick={() => setOnline()} className='button_valid'>Valider</button>
            </div>
        </div>
    );
}

export default VerifRealization;